import React from "react";
import "../styles/faq.scss";
import axiosInstance from "./Common/axiosInstance";

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      html: null
    };
  }

  componentDidMount() {
    axiosInstance.get('https://wordpress-241355-3176562.cloudwaysapps.com/wp-json/wp/v2/posts/5754?password=himalaya')
      .then((htmlData) => {
        this.setState({
          html: htmlData.data.content.rendered,
          isLoading: false
        })
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.setState({
          isLoading: false
        })
      })
  }

  render() {
    return (
      <div>
        <div>
          {!this.state.isLoading && <div className={'root entry-content'}>
            <body
              className='entry-content'
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: this.state.html }}
            />
          </div>}
        </div>
      </div>
    );
  }
}

export default FAQ;
