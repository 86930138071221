import React, { Component } from 'react';
import axiosInstance from './Common/axiosInstance';
import '../styles/welcome.scss';
import CEO from '../assets/images/CEO.jpg';
import relationshipManager from '../assets/images/relationship-manager.jpg';
import { apiEndPointUrl } from './Common/constant';
import { CopyBlock, a11yLight } from "react-code-blocks";
export class Welcome extends Component {

    redirectToNextStep = () => {
        const params = {
            "storeName": this.props.storeName,
            "stepReached": 1
        };
        axiosInstance
            .post(apiEndPointUrl + 'setSettings', params)
            .then((stepRes) => {
                this.props.setTheCurrentStep(1)
                this.props.routeProps.history.push('/acs/settings/step-1');
            }).catch((error) => {
                console.log(error);
            });
    }

    redirectToThemes = () => {
        window.open(`https://${this.props.storeName}/admin/themes?channel=true`, '_blank');
    }

    render() {
        return (
            <div className="custom-container">
                <div className="custom-container-1">
                    <div className="content-wrapper ">
                        <div className="welcomeImgDiv">
                            <img alt="img" src={CEO} />
                        </div>

                        <div className="font-weight-600">Hi,</div>
                        <div className="mt30">I'm Amrish, the CEO of MLVeda. I wanted to personally welcome you
                           and help you setup currency switcher app on your store.
                    </div>
                        <div className="mt30">
                            I will guide you step by step. At any point if you need any help feel free to
              send a mail to <span className="btn-custom-link notranslate">support@mlveda.com</span>
                        </div>
                        <div className="mt30">
                            I wish huge success to you and your store.
                    </div>
                        <div className="start-plan-container">
                            <div>
                                <button className="btn-custom-primary" onClick={this.redirectToNextStep}>Start Setup<span className="lessThan60SecMsg">(Takes less than 60 sec)</span></button>

                            </div>
                            {this.props.appPlan === 'free' && <div>
                                <div className="btn-custom-link">Upgrade to Super Success plan></div>
                            </div>}

                        </div>
                        {/* <div className="blur-text">Take less than a minute</div> */}
                    </div>
                </div>
            </div>
        )
    }
}
