export const apiEndPointUrl = process.env.REACT_APP_API_ENDPOINT;
export const appNameOptions = [
   {
      value: 'bottom_with_left',
      label: 'Auto Currency Switcher',
   }, {
      value: 'bottom_with_right',
      label: 'Auto Currency Switcher',
   }, {
      value: 'top_with_left',
      label: 'Left Top Corner',
   },
]
export const currencySwitcherOptions = [
   {
      value: 'fixed_position',
      label: 'Fixed Position',
      name: 'position'
   }, {
      value: 'inline',
      label: 'Inline with the Header',
      name: 'position'
   },
  // {
  //     value: 'custom_location',
  //     label: 'Custom Location',
  //     name: 'position'
  //  }, 
   {
      value: 'hide',
      label: 'Don\'t Show at all',
      name: 'position'
   }
];

export const postionMesaureOptions = [

   {
      value: '0px',
      label: '0px'
   }, {
    value: '5px',
    label: '5px'
   }, {
      value: '10px',
      label: '10px'
   }, {
      value: '15px',
      label: '15px'
   }, {
      value: '20px',
      label: '20px'
   }, {
      value: '25px',
      label: '25px'
   }, {
      value: '30px',
      label: '30px'
   }, {
      value: '35px',
      label: '35px'
   }, {
      value: '40px',
      label: '40px'
   }
]


export const priceFormatOptions = [
   {
      value: 'with',
      label: 'With Currency'
   }, {
      value: 'without',
      label: 'Without Currency'
   }
];

export const roundUptoOptions = [
  {
     value: 1,
     label: "1.00 (recommended)"
  }, {
     value: 0.99,
     label: '0.99'
  }, {
    value: 0.95,
    label: '0.95'
  }, {
    value: 0.90,
    label: '0.90'
  }, {
    value: 0.75,
    label: '0.75'
  }, {
    value: 0.50,
    label: '0.50'
  }, {
    value: 0.25,
    label: '0.25'
  }
];

export const pricingRoundingOptions = [
  {
     value: true,
     label: 'Yes'
  }, {
     value: false,
     label: 'No'
  }
];

export const booleanOptions = [
   {
      value: true,
      label: 'Yes'
   },
   {
      value: false,
      label: 'No'
   }
]

export const flagStyleOptions = [
   {
      value: 'rectangle',
      label: 'Rectangle'
   },
   {
      value: 'circle',
      label: 'Circle'
   }
]


export const fontSizeOptions = [
   {
      value: 'inherit',
      label: 'inherit'
   },
   {
      value: '10px',
      label: '10px'
   }, {
      value: '11px',
      label: '11px'
   }, {
      value: '12px',
      label: '12px'
   }, {
      value: '13px',
      label: '13px'
   }, {
      value: '14px',
      label: '14px'
   }, {
      value: '15px',
      label: '15px'
   }, {
      value: '16px',
      label: '16px'
   }, {
      value: '18px',
      label: '18px'
   }
]


export const borderPaddingOptions = [

   {
      value: '1px',
      label: '1px'
   }, {
      value: '2px',
      label: '2px'
   }, {
      value: '3px',
      label: '3px'
   }, {
      value: '4px',
      label: '4px'
   }, {
      value: '5px',
      label: '5px'
   }, {
      value: '6px',
      label: '6px'
   }, {
      value: '7px',
      label: '7px'
   }, {
      value: '7px',
      label: '8px'
   }
]