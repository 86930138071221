import React, { Component } from 'react'
import { priceFormatOptions, booleanOptions, flagStyleOptions, fontSizeOptions, borderPaddingOptions, apiEndPointUrl } from '../Common/constant';
import Select from 'react-select';
import '../../styles/look.scss';
import AdvancedLook from './AdvancedLook';
import { SetupBoard } from '../Common/SetupBoard';
import { SetupModalWrapper } from '../Common/SetupModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import leftArrow from '../../assets/images/left-arrow.svg';
import usd from '../../assets/images/usd.jpg';
import ProConfirmPopup from '../Common/ProConfirmPopup';
import { SuccessMsg, ErrorMsg } from '../Common/toastConfig';
import { toast } from 'react-toastify';
import axiosInstance from '../Common/axiosInstance';

export class Look extends Component {
      constructor(props) {
            super(props)
            this.state = {
                  currencyFormat: {},
                  displayFlag: {},
                  flagStyle: {},
                  borderLook: {},
                  textColor: 'inherit',
                  textHoverColor: 'inherit',
                  borderLineColor: 'inherit',
                  backgroundColor: 'inherit',
                  hoverBackgroundColor: 'inherit',
                  textColorPopOver: false,
                  textHoverColorPopOver: false,
                  borderLineColorPopOver: false,
                  backgroundColorPopOver: false,
                  hoverBackgroundColorPopOver: false,
                  isShowErrorForColor: false,
                  isSetupModalOpen: false,
                  // fontSize: '12px',
                  // borderPadding: '1px',
                  isLoading: false,
                  isModalOpen: false,
                  showAdvanced: false,
                  currentPopupOpenedFor: null
            }
      }

      componentDidMount() {
            this.setState({
                  currencyFormat: priceFormatOptions.filter((item) => item.value === this.props.currencyFormat)[0],
                  displayFlag: booleanOptions.filter((item) => item.value === this.props.displayFlag)[0],
                  flagStyle: this.props.displayFlag && this.props.appPlan != "free" ? flagStyleOptions.filter((item) => item.value === this.props.flagStyle)[0] : null,
                  borderLook: booleanOptions.filter((item) => item.value === this.props.borderLook)[0],
                  textColor: this.props.textColor === 'inherit' ? 'Default' : this.props.textColor,
                  textHoverColor: this.props.textHoverColor === 'inherit' ? 'Default' : this.props.textHoverColor,
                  borderLineColor: this.props.borderLineColor === 'inherit' ? 'Default' : this.props.borderLineColor,
                  hoverBackgroundColor: this.props.hoverBackgroundColor === 'inherit' ? 'Default' : this.props.hoverBackgroundColor,
                  backgroundColor: this.props.backgroundColor === 'inherit' ? 'Default' : this.props.backgroundColor,
                  // fontSize: fontSizeOptions.filter((item) => item.value === this.props.fontSize)[0],
                  // borderPadding: borderPaddingOptions.filter((item) => item.value === this.props.borderPadding)[0]

            })
      }
      /* 
         set the pop over for color picker box
      */
      toggleColorPicker = (nameOfPicker) => {
            this.setState({
                  [nameOfPicker]: !this.state[nameOfPicker],
                  isShowErrorForColor: false
            })
      }
      /*
        close the modal when click on outside
     */
      toggleSetupVideoModal = () => {
            this.setState({
                  isSetupModalOpen: !this.state.isSetupModalOpen
            })
      }
      handleColorChange = (value, nameOfTheColor) => {
            var isOk = /^#[0-9A-F]{6}$/i.test(value)
            if (isOk) {
                  this.setState({
                        [nameOfTheColor]: value,
                        isShowErrorForColor: false
                  })
            } else {
                  this.setState({
                        isShowErrorForColor: true
                  })
            }
      }
      handleChangeComplete = (color, nameOfColor) => {
            this.setState({ [nameOfColor]: color.hex });
      };

      handleDefaultState = (value, nameOfField) => {
            // if (nameOfField == 'fontSize') {
            //       this.setState({ fontSize: fontSizeOptions.filter((item) => item.value === value)[0] });
            // } else {
            //       this.setState({ [nameOfField]: value });
            // }

            this.setState({ [nameOfField]: value });
      }
      /*
         set the state bases on selected dropdown
      */
      handleDropDownChange = (selectedDropDownOption, nameOfDropDown) => {
            if (nameOfDropDown == 'flagStyle' && selectedDropDownOption && this.props.appPlan == "free") {
                  this.openModal('flagStyleNode');
                  return;
            }

            if (nameOfDropDown == 'displayFlag' && selectedDropDownOption.value && this.props.appPlan == "free") {
                  this.openModal('displayFlagNode');
                  return;
            }

            this.setState({
                  [nameOfDropDown]: selectedDropDownOption
            }, () => {
                  if (nameOfDropDown == 'displayFlag' && this.state.displayFlag && !this.state.displayFlag.value) {
                        this.setState({ flagStyle: null });
                  }
                  if (nameOfDropDown == 'displayFlag' && this.state.displayFlag && this.state.displayFlag.value) {
                        if (this.props.appPlan == "free") {
                              this.setState({ flagStyle: null });
                        } else {
                              this.setState({ flagStyle: flagStyleOptions.filter((item) => item.value === this.props.flagStyle)[0] });
                        }
                  }
            });
      }
      /*
          save the current state, update the patent state and goto next step
       */
      saveState = () => {
            this.setState({
                  isLoading: true,
            });

            const postData = {
                  currencyFormat: this.state.currencyFormat.value,
                  displayFlag: this.state.displayFlag.value,
                  flagStyle: this.state.flagStyle && this.state.flagStyle.value ? this.state.flagStyle.value : this.props.flagStyle,
                  borderLook: this.state.borderLook.value,
                  storeName: this.props.storeName,
                  textColor: this.state.textColor === 'Default' ? 'inherit' : this.state.textColor,
                  textHoverColor: this.state.textHoverColor === 'Default' ? 'inherit' : this.state.textHoverColor,
                  borderLineColor: this.state.borderLineColor === 'Default' ? 'inherit' : this.state.borderLineColor,
                  hoverBackgroundColor: this.state.hoverBackgroundColor === 'Default' ? 'inherit' : this.state.hoverBackgroundColor,
                  backgroundColor: this.state.backgroundColor === 'Default' ? 'inherit' : this.state.backgroundColor,
                  // fontSize: this.state.fontSize.value,
                  // borderPadding: this.state.borderPadding.value
            }

            axiosInstance
                  .post(apiEndPointUrl + 'setSettings', postData)
                  .then((res) => {
                        this.props.getStateFromChild({ ...postData });
                        toast.success(<SuccessMsg msg={"Saved Successfully."} />);
                        // this.props.history.push('/acs/settings/advanced-setting/step-2')
                  }, (error) => {
                        this.setState({
                              isLoading: false,
                        });
                        toast.error(<ErrorMsg msg={"Your session ended. Please login and try again."} />);
                  });

      }

      openModal = (nodeName) => {
            this.setState({
                  isModalOpen: true,
                  currentPopupOpenedFor: nodeName
            })
      }

      toggleModal = (nodeName) => {
            this.setState({
                  isModalOpen: !this.state.isModalOpen
            });
            this[nodeName].focus();
      }

      upgradePlan = () => {
            window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?upgrade=yes&shop=" + this.props.storeName
      }

      render() {
            let flagStyleForBorder = {};
            if (this.state.flagStyle) {
                  if (this.state.flagStyle.value === 'rectangle') {
                        flagStyleForBorder['borderRadius'] = '0%';
                        flagStyleForBorder['width'] = '45px';
                        flagStyleForBorder['height'] = '25px';
                  }
                  else if (this.state.flagStyle.value === 'square') {
                        flagStyleForBorder['borderRadius'] = '0%';
                        flagStyleForBorder['width'] = '30px';
                        flagStyleForBorder['height'] = '30px';
                  }
                  else if (this.state.flagStyle.value === 'circle') {
                        flagStyleForBorder['borderRadius'] = '50%';
                        flagStyleForBorder['width'] = '30px';
                        flagStyleForBorder['height'] = '30px';
                  }
            }


            return (
                  <div>
                        <div className="step-title">
                              <div className="title-container">
                                    <div>
                                          <span onClick={() => this.props.history.push('/acs/settings')}
                                                className="btn-custom-back ">  <img src={leftArrow} />
                                          </span>

                                          <label className="custom-label">Look:</label>
                                    </div>
                                    <div>Now,lets decide the Look of the currency switcher in your store. </div>
                                    <div>If you want to customize the switcher, use advanced options else ignore it.
                  <span className="btn-custom-link help-link" onClick={
                                                () => this.setState({ isSetupModalOpen: true })
                                          } > (Help me setup this step)
                     </span>
                                    </div>
                              </div>
                              <div className="section-wrapper">

                                    <div className="row">
                                          <div className="col-md-12">
                                                {/* starting of Price format */}
                                                <div className="col-md-6 col-xs-12 lookLeftDiv">
                                                      <div className="custom-label">
                                                            Price Format</div>
                                                      <Select
                                                            className='custom-select-box notranslate'
                                                            classNamePrefix='custom-select-box'
                                                            value={this.state.currencyFormat}
                                                            isSearchable={false}
                                                            onChange={(e) => {
                                                                  this.handleDropDownChange(e, 'currencyFormat')
                                                            }}
                                                            options={priceFormatOptions} />
                                                </div>

                                                {/* <div className="col-md-6 col-xs-12 lookRightDiv">

                                                      <div className="preview-wrapper">
                                                            <span>
                                                                  <img style={flagStyleForBorder} className="usd-image" src={usd} />
                                                            </span>
                                                            <span>USD</span>
                                                      </div>
                                                </div> */}
                                                {/* ending of price format */}
                                                {/* starting of Display flag */}
                                                <div className="col-md-6 col-xs-12 lookLeftDiv">
                                                      <div className="custom-label partition-div-mt-30">
                                                            Display Flag<span className="proLink">Pro</span></div>

                                                      <Select
                                                            className='custom-select-box notranslate'
                                                            classNamePrefix='custom-select-box'
                                                            value={this.state.displayFlag}
                                                            isSearchable={false}
                                                            ref={(ref) => this.displayFlagNode = ref}
                                                            onChange={(e) => {
                                                                  this.handleDropDownChange(e, 'displayFlag')
                                                            }}
                                                            options={booleanOptions} />
                                                </div>
                                                {/* ending of price format */}

                                                {/* starting of Flag style */}
                                                <div className="col-md-6 col-xs-12 lookLeftDiv">
                                                      <div className="custom-label partition-div-mt-30">
                                                            Flag Style<span className="proLink">Pro</span></div>
                                                      <Select
                                                            placeholder={this.state.displayFlag && (!this.state.displayFlag.value || this.props.appPlan == "free") ? 'NA' : 'Select...'}
                                                            className='custom-select-box notranslate'
                                                            classNamePrefix='custom-select-box'
                                                            value={this.state.flagStyle}
                                                            isSearchable={false}
                                                            ref={(ref) => this.flagStyleNode = ref}
                                                            onChange={(e) => {
                                                                  this.handleDropDownChange(e, 'flagStyle')
                                                            }}
                                                            options={flagStyleOptions} />
                                                </div>
                                                {/* ending of Flag style  */}

                                                {/* starting of More Customize Styles */}
                                                {/* <div className="col-md-7 lookLeftDiv inline-block moreSwitcherDiv">
                                                      <div className="custom-label partition-div-mt-30">
                                                            More Switcher Customization</div>
                                                </div> */}
                                                <div className="advanceOption">
                                                      <div className="btn-custom-link" onClick={() => {
                                                            this.setState({ showAdvanced: !this.state.showAdvanced })
                                                      }} >Advanced Customization > </div>
                                                </div>
                                                {/* <div className="lookRightDiv">
                                                      <div className="moreSwitcherOption">
                                                            <span></span>
                                                            <div onClick={() => { this.setState({ isCustomized: !this.state.isCustomized }) }}
                                                                  className={'switch-wrapper ' + (this.state.isCustomized ? 'on' : 'off')}>
                                                                  {/* + (this.state.isMessageDisplay ? 'on' : 'off') */}
                                                {/* <div className="is on">
                                                      Yes
                                                                  <div className="is off">No</div>
                                                </div>
                                          </div>
                                    </div>
                                    <div className="clear-fix"></div>
                              </div> */} 
                                                {/* ending of More Customize Styles  */}

                              {this.state.showAdvanced && <div>
                                    {/* starting of Flag style */}
                                    <div className="col-md-6 col-xs-12 lookLeftDiv">
                                          <div className="custom-label partition-div-mt-30">
                                                Display Border</div>
                                          <Select
                                                className='custom-select-box notranslate'
                                                classNamePrefix='custom-select-box'
                                                value={this.state.borderLook}
                                                isSearchable={false}
                                                onChange={(e) => {
                                                      this.handleDropDownChange(e, 'borderLook')
                                                }}
                                                options={booleanOptions} />
                                    </div>
                                    {/* ending of Flag style  */}

                                    <AdvancedLook handleChangeComplete={this.handleChangeComplete} handleColorChange={this.handleColorChange} handleDefaultState={this.handleDefaultState}
                                          handleDropDownChange={this.handleDropDownChange} toggleColorPicker={this.toggleColorPicker} {...this.state} />
                              </div>}

                        </div>

                  </div>
                  <div className="save-btn-wrapper">
                        <button onClick={() => {
                              this.props.history.push('/acs/settings')
                        }} className="btn-custom-secondary mr25">Back</button>
                        <button onClick={() => {
                              this.saveState()

                        }} className="btn-custom-primary">
                              <span>{this.state.isLoading ? <FontAwesomeIcon spin size={'1x'} icon={faSpinner} /> : null}</span>
                              Save
                     </button>
                  </div>
                              </div >
                  {/* starting of right side setuo video section   */ }
                  < SetupBoard link={"https://www.youtube.com/embed/nU_5a9PHbnw?autoplay=1"} page={"look"} />
                  {/* ending of right side setuo video section   */ }
                  < div className = "clear-fix" ></div >

                        </div >
                  {
                        this.state.isModalOpen ?
                              <ProConfirmPopup upgradePlan={this.upgradePlan} currentPopupOpenedFor={this.state.currentPopupOpenedFor} toggleModal={this.toggleModal} /> : null
                  }
            {
                  this.state.isSetupModalOpen ?
                  <SetupModalWrapper toggleModal={this.toggleSetupVideoModal} /> : null
            }
                  </div >
            )
      }
}
