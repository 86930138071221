import React from 'react';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/scss/bootstrap.scss';
import 'react-toastify/dist/ReactToastify.css';
import './styles/app.scss';
import './styles/common/styles.scss';
import axiosInstance from './components/Common/axiosInstance';
import Basic from './components/basi';
import {
   BrowserRouter as Router,
   Route,
   Switch,
   Redirect
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Pricing } from './components/Pricing';
import { PricingPlan } from './components/PricingPlan';
import { Welcome } from './components/Welcome';
import { AcsHome } from './components/Common/AcsHome';
import { apiEndPointUrl } from './components/Common/constant';
import { UninstallationSteps } from './components/UninstallationSteps';
import AppToggleModal from "./components/AppToggleModal";



export class App extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         toggleAppStatusModalOpen: false,

         data: {
            positionCatagory: undefined,
            activeTab: 1,
            enable: false,
            autoSwitch: false,
            roundDecimal: false,
            hideDecimal: false,
            displayFlag: false,
            position: 'top_with_right',
            positionBottom: false,
            positionRight: false,
            positionLeft: false,
            positionTop: false,
            currencyFormat: 'with',
            currency: {},
            supportedCurrencies: "INR",
            defaultCurrency: "INR",
            password_enabled: false,
            isSetupCompleted: false,
            storeName: 'disneytoys-t1.myshopify.com',
            stepReached: null,
            sampleReview: '',
            isReviewGiven: false,
            isEnable:false,
            homeCurrency:'',
            isMcpInstalled: false
         },
         isLoading: true
      }
   }

   componentDidMount() {

      const queryParams = this.getParams(window.location)
      // let postFormData = {
      //    storeName: 'disneytoys-t1.myshopify.com'
      // };
      let page = '';
      const url = window.location.href.split('?')[0];
      if(url.indexOf('uninstallation-steps') !== -1) {
         this.setState({ isLoading: false });
      } else {
         if(url.indexOf('pricing') !== -1 && url.indexOf('pricing-rule') === -1 && url.indexOf('pricing-plan') === -1){
           page = '&page=pricing';
         }
         const apiUrl = apiEndPointUrl + 'getAllSettings?shop=' + queryParams.shopName + page;
         axiosInstance
            .get(apiUrl)
            .then((res) => {
               window.$crisp=[];
               window.CRISP_WEBSITE_ID="9a70b14e-b8db-48b2-8773-95eb66b91cee";
               (function(){
                  var d=document;
                  var s=d.createElement("script");
                  s.src="https://client.crisp.chat/l.js";
                  s.async=1;
                  d.getElementsByTagName("head")[0].appendChild(s);
                  window.$crisp.push(["set", "session:segments", [["acs"]]]);
                  window.$crisp.push(["set","session:data",["storename", res.data.storeName]]);
               })();
               
   
               this.getSampleReview(res.data, queryParams.shopName);
               this.setState({
                 data: {
                    ...this.state.data,
                    storeName: res.data.storeName,
                 },
                  toggleAppStatusModalOpen: res.data.isSetupCompleted && !res.data.appEmbedStatus,
               });
   
            }).catch(error => {
               window.$crisp=[];
               window.CRISP_WEBSITE_ID="9a70b14e-b8db-48b2-8773-95eb66b91cee";
               (function(){
                  var d=document;
                  var s=d.createElement("script");
                  s.src="https://client.crisp.chat/l.js";
                  s.async=1;
                  d.getElementsByTagName("head")[0].appendChild(s);
                  window.$crisp.push(["set", "session:segments", [["acs"]]]);
               })();
   
             this.setState({ isLoading: false });
           });
      }
   };



   getSampleReview(responseData, shopName) {
    // const queryParams = this.getParams(window.location)
      const url = apiEndPointUrl + 'getReviews?shop=' + responseData.storeName
      axiosInstance
         .get(url)
         .then((response) => {
            this.setState({
               data: {
                  ...this.state.data,
                  ...responseData,
                  sampleReview: response.data.sampleReview.message,
                  shopName: shopName
               },
               isLoading: false

            })
         })
         .catch(error => {
          this.setState({ isLoading: false });
        });
   }

   /*
      get the updated state from child and set
   */
   getStateFromChild = (newState) => {
      const newStateObj = {
         data: {
            ...this.state.data,
            ...newState
         }
      };

      if (newStateObj.data.appEmbedStatus !== false) {
         newStateObj.data.toggleAppStatusModalOpen = true;
      }

      this.setState(newStateObj);
   }

   /*
     set the current step value in acs state to show progress bar
   */
   getTheCurrentStep = (currentStep) => {
      this.setState({
         data: {
            ...this.state.data,
            stepReached: currentStep
         }

      })
   }



   /* 
       get the querystring value from the url
       here we are using window location serach params
    */
   getParams = (location) => {
      const searchParams = new URLSearchParams(location.search);
      return {
         shopName: searchParams.get('shop') || '',
         planName: searchParams.get('plan') || '',
         isSetupCompleted: searchParams.get('isSetupCompleted') ? false : true
      };
   }


   render() {
      return (
          <>
         <Router>
            <>
               <AppToggleModal
                   isOpen={this.state.toggleAppStatusModalOpen}
                   storeName={this.state.data.storeName}
                   getStateFromChild={this.getStateFromChild}
                   onClose={() => this.setState({ toggleAppStatusModalOpen: false })}
               />
            {!this.state.isLoading &&  <div>

               <div className="App">

                  {!this.state.data.isSetupCompleted && <Switch>
                     <Route exact path='/' component={(props) => { return <Redirect to={this.state.data.stepReached == -1 ? '/pricing': (this.state.data.stepReached == 0 ? '/welcome' : `/acs/settings/step-${this.state.data.stepReached}`)} /> }} />
                     <Route path="/acs" component={(props) => <AcsHome setTheCurrentStep={this.getTheCurrentStep} getStateFromChild={this.getStateFromChild}  {...this.state.data} {...props} />} /> 
                     <Route path="/pricing" component={(props) => <Pricing   {...this.state.data} {...props} setTheCurrentStep={this.getTheCurrentStep} routeProps={props} />} />
                     <Route path="/uninstallation-steps" component={(props) => <UninstallationSteps  />} />
                     <Route path="/welcome" component={(props) => <Welcome   {...this.state.data} {...props} setTheCurrentStep={this.getTheCurrentStep} routeProps={props} />} />
                     <Route path='*' exact={true} component={(props) => { return <Redirect to={this.state.data.stepReached == -1 ? '/pricing': (this.state.data.stepReached == 0 ? '/welcome' : `/acs/settings/step-${this.state.data.stepReached}`)} /> }} />
                  </Switch>}

                  {this.state.data.isSetupCompleted && <Switch>
                     {/* <Route exact path='/' component={(props) => { return <Redirect to={ !this.state.data.isReviewGiven ?  '/acs/settings/last-step' : '/acs/dashboard'} /> }} /> */}
                     <Route exact path='/' component={(props) => { return <Redirect to={ '/acs/dashboard'} /> }} />
                     <Route path="/pricing-plan" component={(props) => <PricingPlan   {...this.state.data} {...props} setTheCurrentStep={this.getTheCurrentStep} routeProps={props} />} />
                     <Route path="/acs" component={(props) => <AcsHome setTheCurrentStep={this.getTheCurrentStep} getStateFromChild={this.getStateFromChild}  {...this.state.data} {...props} />} /> 
                     <Route path='*' exact={true} component={(props) => { return <Redirect to={'/acs/dashboard'} /> }} />
                  </Switch>}

               </div>
               <ToastContainer autoClose={2000} />
            </div>}
            </>
         </Router>
</>
      )
   }
}
export default App;