import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Header } from '../Common/Header';
import '../../styles/key-customization.scss';
import {
   BrowserRouter as Router,
   Route,
   NavLink,
   Switch,
   Redirect
} from 'react-router-dom';

import { Settings } from './Settings';
import ImportantNotice from '../ImportantNotice';
import Dashboard from '../dashboard';
import Help from '../Help';
import FAQ from '../Faq';


export class AcsHome extends Component {
   constructor(props) {
      super(props)
      this.state = {
         isEnableSetting: false,
         isModalOpen: true,
         isPopupClosed: false
      }
   }
   componentDidMount() {
      // if review is not given then take last step 
      // if (this.props.isSetupCompleted && !this.props.isReviewGiven) {
      //    this.props.history.push('/acs/setting/last-step');
      // }
   }

   toggleModal = () => {
      this.setState({
            isModalOpen: !this.state.isModalOpen,
            isPopupClosed: true
      });
   }

   render() {
      const stepCompletion = this.props.stepReached === 1 ? 15 : this.props.stepReached === 2 ? 45 : this.props.stepReached === 3 ? 80 : 100
      return (
         <div>
            {window.location.pathname.indexOf("last-step") === -1 && <Header {...this.props} stepCompletion={stepCompletion} />}
            <div className={"custom-container"}>

               {/* {this.props.isSetupCompleted ? <ResponsiveMenu {...this.props} /> : null} */}

               {/* starting of side bar section */}
               {/* {this.props.isSetupCompleted && (window.location.href).indexOf('last-step') === -1 ?
                  <div className="side-menu">
                     <ul className="page-navigation">
                        <li>
                           <NavLink activeClassName="selected" className="navbar-link" to={`${this.props.match.url}/dashboard`}>
                              Dashboard
                           </NavLink>
                        </li>
                        <li>
                           <NavLink activeClassName="selected" className="navbar-link "
                              to={`${this.props.match.url}/settings`}>
                              Settings
                           </NavLink>
                        </li>
                        <li>
                           <NavLink activeClassName="selected" className="navbar-link" to={`/acs/help`}>
                              Help
                           </NavLink>
                        </li>

                     </ul>
                  </div>
                  : null} */}
               {/* end of side bar section */}

               {this.props.isSetupCompleted && this.props.isMcpInstalled && <Modal className="impNoticeModal" isOpen={this.state.isModalOpen} fade={false} toggle={this.toggleModal}>

                  <ModalBody className="modal-body-wrapper impNoticeDialog" >
                     <div>
                        <div className="closeBtn" onClick={this.toggleModal}>x</div>
                        <div className="noticeDiv-store mb10">
                           <div className="importantIcon-store">!</div>
                        </div>
                        <div className="impNoteText">Important Notice</div>
                        <div className="font-22">This app is installed by MLVeda team. This <span className="underline">app is must</span> for <span className="font-weight">Multi Country Pricing</span> app to work properly on your store.</div>
                        <div className="font-22 mt30"><span className="color-red">Please do not remove this app.</span> Contact <span className="btn-custom-link font-22 notranslate">support@mlveda.com</span> if you have any queries.</div>
                     </div>
                  </ModalBody>

               </Modal>}

               {/* starting of each step section*/}
               <div className={"content-wrapper " + (!this.props.isSetupCompleted ? "setup-done" : "") + (this.state.isPopupClosed && this.props.isMcpInstalled ? " margin" : "")}>
                  <Switch>
                     <Route path={`${this.props.match.path}/settings`} component={(props) => <Settings routeProps={props} {...this.props} />} />
                     {/* <Route path={`${this.props.match.path}/important-notice`} component={(props) => <ImportantNotice routeProps={props} {...this.props} />} /> */}
                     <Route path={`${this.props.match.path}/dashboard`} component={(props) => <Dashboard routeProps={props} {...this.props} />} />
                     <Route path={`${this.props.match.path}/help`} component={(props) => <Help />} />
                     <Route path={`${this.props.match.path}/faq`} component={(props) => <FAQ />} />
                     <Route path='*' exact={true} component={(props) => { return <Redirect to={'/acs/dashboard'} /> }} />
                  </Switch>
               </div>
               {/* end of each step section*/}
               <div className="clear-fix"></div>

            </div>
            {this.state.isPopupClosed && this.props.isMcpInstalled && <div className="footerMain">
               <div className="noticeDiv-store inner">
                     <div className="importantIcon-store">!</div>
               </div>
               <div className="inner pl25"><span className="color-red">Please do not remove this app.</span> This <span className="underline">app is must</span> for <span className="font-weight">Multi Country Pricing</span> app to work properly on your store.</div>
            </div>}
         </div>
      )
   }
}
