import React from 'react';
import '../styles/help.scss';
import relationshipManager from '../assets/images/relationship-manager.jpg';

class Help extends React.Component {

  render() {
    return (
      <div className="content-wrapper-help">
        <div className="section-wrapper">
          <div className="header-section">
            <div className="heading">Dedicated Relationship Manager</div>
            <div>Need any help with setup or customization? Our team is always there to resolve your queries.</div>
          </div>
          <div className="middle-section">
            <div className="img-section">
              <img src={relationshipManager} alt="Manager" />
              <div className="name">Reshma</div>
              <div>Currency Switcher expert!</div>
            </div>
            <div className="mt20">Send an email to <span className="btn-custom-link notranslate">support@mlveda.com</span>. We will reply you within one business day.</div>
          </div>
          <div className="note-section">
          <span className="font-weight-600">Note:</span> Please provide your store access to <span className="btn-custom-link">shopify@mlveda.com</span>, if it requires us to access your store.
          </div>
        </div>
      </div>
    )
  }
}

export default Help;