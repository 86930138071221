import React, { Component } from 'react';
import leftArrow from '../../assets/images/left-arrow.svg';
import pageFly from '../../assets/images/pageFly.png';
import '../../styles/partners-app.scss';

export class PartnerApps extends Component {
   constructor(props) { 
      super(props)
      this.state = {
      }
   }

   render() {
      return (
         <div>
            <div className="step-title">
               <div className="title-container">
                  <div>
                    <span onClick={() => this.props.history.push('/acs/settings')} className="btn-custom-back ">
                        <img src={leftArrow} alt="left-arrow-"/>  </span>
                    <label className="custom-label">ACS Partner Deals:</label>
                  </div>
               </div>
               <div>
                 <div className="partners-app-heading"><span>Work with our partners</span> and grow your business</div>
                 <div>
                  <div className="partners-app-card">
                    <div className="img-section"><img src={pageFly} alt="PageFly" /></div>
                    <div className="description-section">
                      <div className="app-name">
                         PageFly Landing Page builder
                         {/* <span className="price-off">40% off</span> */}
                      </div>
                      <div className="app-desc">Build high-converting pages: homepage, product pages, landing pages and more without coding, just drag and drop</div>
                    </div>
                    <div className="button-section">
                      <button className="btn-custom-primary" onClick={() => { window.open("https://apps.shopify.com/pagefly?utm_source=app-providers&utm_medium=mlveda&utm_campaign=app-listing&utm_content=in-app", "_blank") }}>Install</button>
                    </div>
                  </div>
                 </div>
               </div>
            </div>
         </div>
            )
         }
      }
