import React, {useState} from 'react';
import {Alert, Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import deepLink1 from '../assets/images/AppEmbed.png';
import axiosInstance from "./Common/axiosInstance";
import {apiEndPointUrl} from "./Common/constant";

export default function AppToggleModal({isOpen, getStateFromChild, storeName, onClose}) {
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState(null);

    const onVerifyClick = async () => {
        setLoading(true);
        setMsg(null);

        const apiUrl = apiEndPointUrl + 'getAllSettings?shop=' + storeName + '&page=';
        try {
            const res = await axiosInstance
                .get(apiUrl);
            const settings = res.data;
            if (settings.appEmbedStatus === false) {
                setMsg('Please enable app extension');
            } else if (settings.enable !== true) {
                const postData = {
                    enable: true,
                    storeName,
                };
                await axiosInstance
                    .post(apiEndPointUrl + 'setAppStatus', postData)
                getStateFromChild({
                    enable: true,
                    appEmbedStatus: true,
                    storeName,
                });
                onClose && onClose();
            } else {
                getStateFromChild({
                    enable: true,
                    appEmbedStatus: true,
                    storeName,
                });
                onClose && onClose();
            }
        } catch (err) {
            setMsg('Something went wrong!');
        }
        setLoading(false);
    };

    return (
        <Modal isOpen={isOpen}>
            <div className="modal-header">
                <div className='flex-column'>
                    <h5 className="modal-title">Auto Currency Switcher app is disabled on your store</h5>
                    <span>Follow the below steps.</span>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                    setMsg(false);
                    onClose && onClose();
                }}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <ModalBody>
                {loading && (
                    <div className='position-absolute d-flex justify-content-center align-items-center h-100 w-100'
                         style={{zIndex: 999, backgroundColor: '#8080805c', top: 0, left: 0}}>
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {msg && (
                    <Alert color="danger">
                        {msg}
                    </Alert>
                )}
                <div className='container flex flex-row'>
                    <ol>
                        <li>
                            Click{' '}
                            <a target='_blank' href={`https://${storeName}/admin/themes/current/editor?context=apps&activateAppId=92ab730f-097e-4571-b27c-3f449e098ca3/mlveda-currencies-head`}>
                                here
                            </a>{' '}
                            (It will open in another tab)
                        </li>
                        <li>
                            Enable the app
                        </li>
                        <li>
                            Click on Save button (See on the top right corner of the page)
                        </li>
                        <img alt='Deep link preview' className='img-fluid' src={deepLink1}/>
                    </ol>
                    <Alert color="light" className='border text-dark'>
                            <span>
                               If you are facing any problem then please{' '}
                                <Button className='inline p-0 align-baseline' color="link"
                                        onClick={() => window.$crisp.push(['do', 'chat:open'])}>
                                  contact us{' '}
                                </Button>{' '}
                                and we will revert within 24 hours
                            </span>
                    </Alert>
                </div>
            </ModalBody>
            <ModalFooter className='justify-content-center'>
                <Button color='primary' onClick={onVerifyClick}>Verify</Button>
            </ModalFooter>
        </Modal>
    )
}