import React from 'react';
import '../styles/important-notice.scss';

class ImportantNotice extends React.Component {

  redirectToDashboard = () => {
    this.props.history.push('/acs/dashboard');
  }

  redirectToMulticurrency = () => {
    window.location.href = 'http://34.213.236.229:8080/currencySwitcherNew/mCheckoutLead?shop=' + this.props.storeName; 
  }

  render() {
    return (
      <div className="content-wrapper-impNotice">
        <div className="section-wrapper">
          <div className="noticeDiv">
            <div className="importantIcon">!</div>
            <div className="noteTitle">Important Note</div>
          </div>

          <div>
            Currency switcher app cannot convert the price from checkout page ddue to Shopify restrictions.
          </div>

          <div className="mt30">
            Are you looking to empower your store to checkout also in converted currency! We can help. We have done this for more than 800+ stores please <span className="btn-custom-link">contact us</span> and we will revert you back within 24 hours.
          </div>

          <div className="start-plan-container first">
            <button className="btn-custom-primary blue" onClick={this.redirectToMulticurrency}>Yes, I want Multi Currency Checkout</button>
          </div>

          <div className="start-plan-container">
            <button className="btn-custom-primary red" onClick={this.redirectToDashboard}>I don't want Currency Checkout</button>
          </div>

          <div className="start-plan-container">
            <button className="btn-custom-primary green" onClick={this.redirectToDashboard}>I have already installed it!</button>
          </div>
        </div>
      </div>
    )
  }
}

export default ImportantNotice;