import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { apiEndPointUrl } from './constant';
import axiosInstance from './axiosInstance';
import { toast } from 'react-toastify';
import { SuccessMsg } from '../Common/toastConfig';
import currencies from '../../assets/images/currencies.png';
import switcherLocation from '../../assets/images/switcher-location.png';
import design from '../../assets/images/design.png';
import pricingRules from '../../assets/images/pricing-rules.png';
import cartNotice from '../../assets/images/cart-notice.png';
import storeIntegration from '../../assets/images/store-integration.png';
import pricingPlan from '../../assets/images/pricing-plan.png';
import apps from '../../assets/images/apps.png';
import AppToggleModal from "../AppToggleModal";
import InvoiceHero from '../../assets/images/invoiceHero.png';
import Offers365 from '../../assets/images/offers365.png';

export class SettingMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      autoSwitch: undefined,
      isModalOpen: false,
      isToggleModalOpen: false,
    }
  }

  componentDidMount() {
    this.setState({ autoSwitch: this.props.enable });
  }
  /*
      redirect user to specific step page
  */
  redirectUserToSelectedPage = (selectedPage) => {
    this.props.history.push('/acs/settings/' + selectedPage);
  }

  redirectToPricing = () => {
    this.props.history.push('/pricing-plan');
  }

  openModal = () => {
    if (this.props.appEmbedStatus) {
      this.setState({
        isModalOpen: true
      });
    } else this.setState({isToggleModalOpen: true});
  };

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }

  changeAppStatus = () => {
    const postData = {
      enable: !this.state.autoSwitch,
      storeName: this.props.storeName,
    };
    axiosInstance
      .post(apiEndPointUrl + 'setAppStatus', postData)
      .then((res) => {
        this.props.getStateFromChild({ ...postData });
        this.toggleModal();
        this.setState({ autoSwitch: !this.state.autoSwitch }, () => {
          toast.success(<SuccessMsg msg={`${this.state.autoSwitch ? 'Enabled' : 'Disabled'} successfully!`} />)
        });

        // this.props.history.push('/acs/settings/advanced-setting/step-2')
      })
  }


  render() {
    return (
      <div>
        <div>
          <div className="app-status-main">
            <span>Auto Currency Switcher app is <span className={this.props.enable ? !this.props.appEmbedStatus ? 'error' : "success" : "error"}>{this.props.enable ? !this.props.appEmbedStatus ? "Disabled" : "Enabled" : "Disabled"}</span></span>
            <button className="btn-custom-secondary" onClick={() => this.openModal()}>Edit</button>
          </div>
          <AppToggleModal
              onClose={() => this.setState({ isToggleModalOpen: false })}
              getStateFromChild={this.props.getStateFromChild}
              storeName={this.props.storeName}
              isOpen={this.state.isToggleModalOpen}
          />
          <div className="line-seperator"></div>
          <div className="settings-main-div">
            <div className="settings-card" onClick={() => this.redirectUserToSelectedPage('currencies')}>
              <img src={currencies} alt="Currencies" />
              <div className="name">Currencies</div>
              <div className="secondLine">List</div>
            </div>
            <div className="settings-card" onClick={() => this.redirectUserToSelectedPage('location')}>
              <img src={switcherLocation} alt="Switcher Location" />
              <div className="name">Switcher</div>
              <div className="secondLine">Location</div>
            </div>
            <div className="settings-card" onClick={() => this.redirectUserToSelectedPage('advanced-setting/look')}>
              <span className="pro">Pro</span>
              <img src={design} alt="Design" />
              <div className="name">Design</div>
              <div className="secondLine">Settings</div>
            </div>
            <div className="settings-card" onClick={() => this.redirectUserToSelectedPage('advanced-setting/pricing-rules')}>
              <span className="pro">Pro</span>
              <img src={pricingRules} alt="Pricing Rules" />
              <div className="name">Pricing</div>
              <div className="secondLine">Rules</div>
            </div>
            <div className="settings-card mt20" onClick={() => this.redirectUserToSelectedPage('advanced-setting/cart-notice')}>
              <img src={cartNotice} alt="Cart Notice" />
              <div className="name">Cart</div>
              <div className="secondLine">Notice</div>
            </div>
            <div className="settings-card mt20" onClick={() => this.redirectUserToSelectedPage('store-integration')}>
              <img src={storeIntegration} alt="Store Integration" />
              <div className="name">Store</div>
              <div className="secondLine">Integration</div>
            </div>
            <div className="settings-card mt20" onClick={() => this.redirectToPricing()}>
              <img src={pricingPlan} alt="Pricing" />
              <div className="name">Pricing</div>
              <div className="secondLine">Plans</div>
            </div>

          </div>
          {!this.props.isMcpInstalled && <div className="line-seperator"></div>}
          {!this.props.isMcpInstalled && <div className="settings-main-div">
            <div className="settings-card" onClick={() => this.redirectUserToSelectedPage('partner-apps')}>
              <img src={apps} alt="Apps" />
              <div className="name">Partner</div>
              <div className="secondLine">Apps</div>
            </div>
          </div>}
          <div>
          <div className="line-seperator"></div>
                 <div className="partners-app-heading">Other Popular apps by MLVeda</div>
                 <div>
                  <div className="partners-app-card">
                    <div className="img-section"><img src={InvoiceHero} alt="Invoice Hero PDF" /></div>
                    <div className="description-section">
                      <div className="app-name">
                         Invoice Hero PDF
                         {/* <span className="price-off">40% off</span> */}
                      </div>
                      <div className="app-desc">Send PDF invoices to your customers automatically. Generate packaging slips and print orders.</div>
                    </div>
                    <div className="button-section">
                      <button className="btn-custom-primary" onClick={() => { window.open("https://apps.shopify.com/invoice-hero?utm_source=acs-app-providers&utm_medium=mlveda&utm_campaign=app-listing&utm_content=in-app", "_blank") }}>Explore</button>
                    </div>
                  </div>
                 </div>
                 <div>
                  <div className="partners-app-card">
                    <div className="img-section"><img src={Offers365} alt="Offers365 Post Purchase Upsell" /></div>
                    <div className="description-section">
                      <div className="app-name">
                      Offers365 Post Purchase Upsell
                         {/* <span className="price-off">40% off</span> */}
                      </div>
                      <div className="app-desc">Add a post purchase page to the store. Create post purchase upsell and cross sell campaigns for free</div>
                    </div>
                    <div className="button-section">
                      <button className="btn-custom-primary" onClick={() => { window.open("https://apps.shopify.com/post-purchase-upsell-crosssell?utm_source=acs-app-providers&utm_medium=mlveda&utm_campaign=app-listing&utm_content=in-app", "_blank") }}>Explore</button>
                    </div>
                  </div>
                 </div>
               </div>
          {/* <div className="setting-title-wrapper">
                  <label className="custom-label">Basic settings</label>
                  <div>Let's choose the currencies that you want to choose in your Shopify store out of 175+ currencies</div>
               </div>
               <div className="settings-wrapper mt5">
                  <div className="item">
                     <div className="setting-title" onClick={() => this.redirectUserToSelectedPage('currencies')} >
                        Currencies
                  </div>
                     <ul>
                        <li>
                        Choose the list of currencies that you want to use on your store.
                     </li>
                     </ul>
                  </div>
                  <div className="item">
                     <div className="setting-title" onClick={() => this.redirectUserToSelectedPage('location')}>
                        Location
                  </div>
                     <ul>
                        <li>
                        Customise the position of the currency switcher on your store.
                     </li>
                     </ul>
                  </div>
                  <div className="item">
                     <div className="setting-title" onClick={() => this.redirectUserToSelectedPage('store-integration')}>
                        Store Integration
                  </div>
                     <ul>
                        <li>
                        Mandatory settings to integrate the currency switcher on your store.
                     </li>
                     </ul>
                  </div>
               </div>
               <div className="setting-title-wrapper mt50">
                  <label className="custom-label">Advanced settings</label>
                  <div>Let's choose the currencies that you want to choose in your Shopify store out of 175+ currencies</div>
               </div>
               <div className="settings-wrapper mt5">
                  <div className="item">
                     <div className="setting-title" onClick={() => this.redirectUserToSelectedPage('advanced-setting/look')}>
                        Look
                  </div>
                     <ul>
                        <li>
                        Customise the look of your currency switcher.
                     </li>
                     </ul>
                  </div>
                  <div className="item">
                     <div className="setting-title" onClick={() => this.redirectUserToSelectedPage('advanced-setting/pricing-rules')}>
                        Pricing Rules<span className="proLink">Pro</span>
                  </div>
                     <ul>
                        <li>
                        Customise how your customers see your product prices on your store.
                     </li>
                     </ul>
                  </div>
                  <div className="item">
                     <div className="setting-title" onClick={() => this.redirectUserToSelectedPage('advanced-setting/cart-notice')} >
                     Cart Notice
                  </div>
                     <ul>
                        <li>
                        Customise the message that is shown to customers before moving to the checkout page.
                     </li>
                     </ul>
                  </div>
               </div> */}
        </div>
        <Modal className="confirmPopup" style={{ maxWidth: '385px', marginTop: '25vh' }} size={'lg'} isOpen={this.state.isModalOpen} fade={true} toggle={this.props.toggleModal}>

          <ModalBody className="modal-body-wrapper" >
            <div>
              {this.props.isSetupCompleted && <p>Are you sure you want to {this.state.autoSwitch ? 'disable' : 'enable'} this app on your store?</p>}
              {!this.props.isSetupCompleted && <p>Please complete all the installation steps to enable the app in your store.</p>}
            </div>
            {this.props.isSetupCompleted && <div className="confirmActionBtn" style={{ textAlign: 'right' }}>
              <button className="btn-custom-secondary mr25" onClick={this.toggleModal}>No</button>
              <button className="btn-custom-primary" onClick={this.changeAppStatus}>Yes</button>
            </div>}

            {!this.props.isSetupCompleted && <div className="confirmActionBtn" style={{ textAlign: 'right' }}>
              <button className="btn-custom-primary" onClick={this.toggleModal}>OK</button>
            </div>}

          </ModalBody>

        </Modal>
      </div>
    )
  }
}
