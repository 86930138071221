import React, { Component } from 'react'
import { currencySwitcherOptions, apiEndPointUrl } from '../Common/constant';
import Select from 'react-select';
import '../../styles/location.scss';
import RenderPositions from './RenderPositions';
import { SetupBoard } from '../Common/SetupBoard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SetupModalWrapper } from '../Common/SetupModalWrapper';
import macbook from '../../assets/images/macbook.png';
import leftArrow from '../../assets/images/left-arrow.svg';
import { SuccessMsg, ErrorMsg } from '../Common/toastConfig';
import { toast } from 'react-toastify';
import axiosInstance from '../Common/axiosInstance';

export default class Location extends Component {
      constructor(props) {
            super(props)
            // on save set positionCatagory,position,positionBottom,positionTop,positionLeft,positionRight
            this.state = {
                  positionCatagory: undefined,
                  positionBottomLeftChecked: false,
                  positionBottomRightChecked: false,
                  positionTopLeftChecked: false,
                  positionTopRightChecked: false,
                  showAdvanced: false,
                  positionBottom: '0px',
                  positionTop: '0px',
                  positionLeft: '0px',
                  positionRight: '0px',
                  isSetupModalOpen: false,
                  isLoading: false,
                  showMessageForInlineHeader: false,
                  showMessageForFixed: false,
                  showMessage: false
            }
      }
      componentDidMount() {
            let currentSelectedCurrency;
            if (this.props.position === 'inline' || this.props.position == 'hide') {
                  currentSelectedCurrency = currencySwitcherOptions.filter((item) => item.value === this.props.position)[0]
            } else {
                  currentSelectedCurrency = currencySwitcherOptions.filter((item) => item.value === 'fixed_position')[0];
            }
            if (currentSelectedCurrency && currentSelectedCurrency.value == 'inline') {
                  this.setState({ showMessageForInlineHeader: true });
            } else if (currentSelectedCurrency && currentSelectedCurrency.value == 'fixed_position') {
                  this.setState({ showMessageForFixed: true });
            } else if (currentSelectedCurrency && currentSelectedCurrency.value == 'hide') {
                  this.setState({ showMessage: true });
            }
            if (this.props.position === 'bottom_with_left') {
                  this.setState({
                        positionCatagory: currentSelectedCurrency,
                        positionBottomLeftChecked: true,
                        positionBottomRightChecked: false,
                        positionTopLeftChecked: false,
                        positionTopRightChecked: false,
                        positionBottom: this.props.positionBottom,
                        positionTop: this.props.positionTop,
                        positionLeft: this.props.positionLeft,
                        positionRight: this.props.positionRight,
                  })
            }
            else if (this.props.position === 'bottom_with_right') {
                  this.setState({
                        positionCatagory: currentSelectedCurrency,
                        positionBottomLeftChecked: false,
                        positionBottomRightChecked: true,
                        positionTopLeftChecked: false,
                        positionTopRightChecked: false,
                        positionBottom: this.props.positionBottom,
                        positionTop: this.props.positionTop,
                        positionLeft: this.props.positionLeft,
                        positionRight: this.props.positionRight,
                  })
            }
            else if (this.props.position === 'top_with_left') {
                  this.setState({
                        positionCatagory: currentSelectedCurrency,
                        positionBottomLeftChecked: false,
                        positionBottomRightChecked: false,
                        positionTopLeftChecked: true,
                        positionTopRightChecked: false,
                        positionBottom: this.props.positionBottom,
                        positionTop: this.props.positionTop,
                        positionLeft: this.props.positionLeft,
                        positionRight: this.props.positionRight,
                  })
            }
            else if (this.props.position === 'top_with_right') {
                  this.setState({
                        positionCatagory: currentSelectedCurrency,
                        positionBottomLeftChecked: false,
                        positionBottomRightChecked: false,
                        positionTopLeftChecked: false,
                        positionTopRightChecked: true,
                        positionBottom: this.props.positionBottom,
                        positionTop: this.props.positionTop,
                        positionLeft: this.props.positionLeft,
                        positionRight: this.props.positionRight,
                  })
            } else {
                  this.setState({
                        positionCatagory: currentSelectedCurrency,
                        positionBottomLeftChecked: true,
                        positionBottomRightChecked: false,
                        positionTopLeftChecked: false,
                        positionTopRightChecked: false,
                        positionBottom: this.props.positionBottom,
                        positionTop: this.props.positionTop,
                        positionLeft: this.props.positionLeft,
                        positionRight: this.props.positionRight,
                  })
            }


            this.setState(
                  {
                        positionCatagory: currentSelectedCurrency && currentSelectedCurrency != null ? currentSelectedCurrency : null
                  }
            )
      }
      /*
         get the position measure and set the value
      */
      getPositionMeasure = (selectedValue, nameOfDropDown) => {
            this.setState({
                  [nameOfDropDown]: selectedValue.value
            })
      }

      /*
         deselect all the position checkbox expect selected one
      */
      handleLocationCheckBox(selectedCheckBox) {
            this.setState({
                  positionBottomLeftChecked: false,
                  positionBottomRightChecked: false,
                  positionTopLeftChecked: false,
                  positionTopRightChecked: false,
                  [selectedCheckBox]: true
            })
      }

      /*
        close the modal when click on outside
     */
      toggleSetupVideoModal = () => {
            this.setState({
                  isSetupModalOpen: !this.state.isSetupModalOpen
            })
      }

      /*
       get the value from default currency
     */
      handleDropDownChange = (selectedDropDownOption, nameOfDropDown) => {
            this.setState({
                  positionCatagory: selectedDropDownOption
            });
            if (nameOfDropDown == 'defaultCurrency') {
                  // if (selectedDropDownOption.value == 'inline') {
                  //       this.setState({ showMessage: true });
                  // } else {
                  //       this.setState({ showMessage: true });
                  // }
                  if (selectedDropDownOption.value == 'inline') {
                        this.setState({ 
                              showMessageForInlineHeader: true,
                              showMessageForFixed: false,
                              showMessage: false
                         });
                  } else if(selectedDropDownOption.value == 'fixed_position') {
                        this.setState({ 
                              showMessageForInlineHeader: false,
                              showMessageForFixed: true,
                              showMessage: false
                         });
                  } else {
                        this.setState({ 
                              showMessageForInlineHeader: false,
                              showMessageForFixed: false,
                              showMessage: true 
                        });
                  }
            }
      }

      /*
         save the current state, update the patent state and goto next step
      */
      saveState = () => {
            this.setState({
                  isLoading: true
            })

            let position = '';
            if (this.state.positionBottomLeftChecked) {
                  position = 'bottom_with_left';
            } else if (this.state.positionBottomRightChecked) {
                  position = 'bottom_with_right';
            } else if (this.state.positionTopLeftChecked) {
                  position = 'top_with_left';
            } else if (this.state.positionTopRightChecked) {
                  position = 'top_with_right';
            }
            let postData = {
                  // positionCatagory: this.state.positionCatagory.value,
                  position: this.state.positionCatagory.value === 'fixed_position' ? position : this.state.positionCatagory.value,
                  positionBottom: this.state.positionBottom,
                  positionTop: this.state.positionTop,
                  positionLeft: this.state.positionLeft,
                  positionRight: this.state.positionRight,
                  storeName: this.props.storeName
            }
            if (!this.props.isSetupCompleted) {
                  postData.stepReached = 3;
                  postData.borderLook = this.state.positionCatagory.value === 'fixed_position' ? true : false;
                  postData.backgroundColor = this.state.positionCatagory.value === 'fixed_position' ? '#ffffff' : 'inherit';
            }
            axiosInstance
                  .post(apiEndPointUrl + 'setSettings', postData)
                  .then((res) => {
                        this.props.getStateFromChild({ ...postData, currentStep: 3 })
                        // this.props.setTheCurrentStep('step-3');
                        if (!this.props.isSetupCompleted) {
                              this.props.history.push('/acs/settings/step-3')
                        } else {
                              toast.success(<SuccessMsg msg={"Saved Successfully."} />);
                        }
                  }, (error) => {
                        this.setState({
                              isLoading: false
                        });
                        toast.error(<ErrorMsg msg={"Your session ended. Please login and try again."} />);

                  })

      }

      goBack = () => {
            if (!this.props.isSetupCompleted) {
                  this.props.setTheCurrentStep(1);
                  this.props.history.push('/acs/settings/step-1');
            } else {
                  this.props.history.push('/acs/settings');
            }
      }

      render() {
            return (
                  <div>
                        <div className="step-title">
                              <div className="title-container">
                                    {this.props.isSetupCompleted ? (
                                          <div>
                                                <span onClick={() => this.props.history.push('/acs/settings')}
                                                      className="btn-custom-back ">  <img src={leftArrow} />
                                                </span>
                                                <label className="custom-label">Location:</label>
                                          </div>
                                    ) :
                                          <label className="custom-label">Step 2:</label>
                                    }
                                    <div>Now, lets decide the location of the currency switcher in your store

                     <span className="btn-custom-link help-link" onClick={
                                                () => this.setState({ isSetupModalOpen: true })
                                          } > (Help me setup this step)
                     </span>
                                    </div>
                              </div>
                              <div className="section-wrapper">
                                    {/* starting of Default Currency */}
                                    <div className="">

                                          <div className="custom-label">
                                                Switcher Location</div>
                                          <Select
                                                className='custom-select-box notranslate'
                                                classNamePrefix='custom-select-box'
                                                value={this.state.positionCatagory}
                                                onChange={(e) => {
                                                      this.handleDropDownChange(e, 'defaultCurrency')
                                                }}
                                                options={currencySwitcherOptions} />

                                    </div>
                                    {/* ending of Currency Currency */}
                                    <div className="mt15"></div>

                                    {this.state.showMessageForInlineHeader && <div className="themeSupportedDiv">
                                          <ul>
                                                <li>This feature is supported default by all <span className="btn-custom-link" onClick={() => {window.open("https://docs.google.com/document/d/1zW7wGa5QzSrDuVTWOc-17jfoC7sMzY6WsBRCNB-MVrc/edit", "_blank")}}>popular themes</span> in shopify.</li>
                                                <li>If you have any other theme and wish to blend the switcher in header for desktop and in menu for mobile, then
                                                  <ol>
                                                    <li>Please add <span className="btn-custom-link notranslate">shopify@mlveda.com</span> as staff for your store with permissions to "Themes","Apps" and "Settings" from <a style={{color: "#00a2ff"}} target="_blank" href={`https://${this.props.storeName}/admin/settings/account/new`}>here</a></li>
                                                    <li>Send us an email to <span className="btn-custom-link notranslate">support@mlveda.com</span> with your requirement(s)</li>
                                                  </ol>
                                                </li>
                                          </ul>
                                    </div>}
                                    {this.state.showMessageForFixed && <ul>
                                          <li>Switcher will be fixed always at a selected position even on page scroll.</li>
                                          <li>If you are not satisfied by the look and feel of the switcher, please drop us an email at <span className="btn-custom-link notranslate">support@mlveda.com</span>. We will do the needful and customize the switcher as per your needs for free. You can disable the switcher from the top right of this page in the mean time.</li>
                                    </ul>}
                                    {this.state.showMessage && <ul>
                                          <li>In this option no currency switcher will be displayed but your prices will be converted as per your plan and settings.</li>
                                          <li>We suggest you to use this only if you are in paid plan.</li>
                                    </ul>}


                                    {this.state.positionCatagory && this.state.positionCatagory.value === currencySwitcherOptions[0].value ?
                                          <div>
                                                <div>
                                                      <div className="currency-location">
                                                            <img src={macbook} />
                                                            <div className={`show ${!this.props.isSetupCompleted ? "custom-show" : ""}`}>
                                                                  {this.props.isSetupCompleted && <div>
                                                                        <input
                                                                              className="select-checkbox"
                                                                              type="checkbox"
                                                                              name="positionTopLeftChecked"
                                                                              checked={this.state.positionTopLeftChecked}
                                                                              onChange={() => { }}
                                                                              id="left-top" />
                                                                            <label onClick={() => { this.handleLocationCheckBox('positionTopLeftChecked') }} name="position-label"
                                                                                  className='select-checkbox-label'>
                                                                            </label>
                                                                  </div>}

                                                                  {this.props.isSetupCompleted && <div>
                                                                        <input
                                                                              className="select-checkbox"
                                                                              type="checkbox"
                                                                              name="positionTopRightChecked"
                                                                              checked={this.state.positionTopRightChecked}
                                                                              onChange={() => { }}
                                                                        />
                                                                        <label onClick={() => { this.handleLocationCheckBox('positionTopRightChecked') }} name="position-label"
                                                                              className='select-checkbox-label'>
                                                                        </label>
                                                                  </div>}
                                                                  <div>
                                                                        <input
                                                                              className="select-checkbox"
                                                                              type="checkbox"
                                                                              name="positionBottomLeftChecked"
                                                                              checked={this.state.positionBottomLeftChecked}
                                                                              onChange={() => { }}
                                                                        />
                                                                        <label onClick={() => { this.handleLocationCheckBox('positionBottomLeftChecked') }} name="position-label"
                                                                              className='select-checkbox-label' >
                                                                        </label>
                                                                  </div>

                                                                  <div>
                                                                        <input
                                                                              className="select-checkbox"
                                                                              type="checkbox"
                                                                              name="positionBottomRightChecked"
                                                                              checked={this.state.positionBottomRightChecked}
                                                                              onChange={() => { }}
                                                                        />
                                                                        <label onClick={() => { this.handleLocationCheckBox('positionBottomRightChecked') }} name="position-label"
                                                                              className='select-checkbox-label' >
                                                                        </label>
                                                                  </div>

                                                            </div>
                                                      </div>
                                                </div>

                                                {this.state.positionBottomLeftChecked || this.state.positionBottomRightChecked ||
                                                      this.state.positionTopLeftChecked || this.state.positionTopRightChecked ?
                                                      <div className="btn-custom-link" onClick={() => {
                                                            this.setState({ showAdvanced: !this.state.showAdvanced })
                                                      }} >Advanced <div className={this.state.showAdvanced ? 'expandIcon' : 'inline-block'}>></div>  </div> : null}
                                                <RenderPositions
                                                      getPositionMeasure={this.getPositionMeasure}
                                                      handleDropDownChange={this.handleDropDownChange}
                                                      {...this.state} />
                                          </div> : null}

                                    <div className="save-btn-wrapper">
                                          <button onClick={() => {
                                                this.goBack()
                                          }} className="btn-custom-secondary mr25">Back</button>
                                          <button onClick={() => {
                                                this.saveState()
                                          }} className={"btn-custom-primary " + (this.state.isLoading ? "disabled" : '')}>
                                                <span>{this.state.isLoading ? <FontAwesomeIcon spin size={'1x'} icon={faSpinner} /> : null}</span>

                                                {this.props.isSetupCompleted ? 'Save' : 'Save and goto step 3'}</button>

                                    </div>
                              </div>
                              {/* starting of right side setuo video section   */}
                              <SetupBoard link={"https://www.youtube.com/embed/i8Rw_kdEgxw?autoplay=1"} page={"step2"} />
                              {/* ending of right side setuo video section   */}
                              <div className="clear-fix"></div>
                              {this.state.isSetupModalOpen ?
                                    <SetupModalWrapper toggleModal={this.toggleSetupVideoModal} /> : null}
                        </div>
                  </div>
            )
      }
}
