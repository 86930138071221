import axios from 'axios';

// Add a request interceptor 
var axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent 
  if (process.env.REACT_APP_ENV === 'production') {
      config.withCredentials = true;
      return config;
  } else {
     return config;
  }
  
}, function (error) {
   // Do something with request error 
   return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
  if(response.data.error) {
    return Promise.reject();
  }
  return response;
})

export default axiosInstance;