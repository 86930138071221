import React from 'react';
import '../styles/dashboard.scss';
import { Modal, ModalBody } from 'reactstrap';
import { apiEndPointUrl } from './Common/constant';
import axiosInstance from './Common/axiosInstance';
import { toast } from 'react-toastify';
import { SuccessMsg } from './Common/toastConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import pageFly from '../assets/images/pageFly.png';
import "../styles/partners-app.scss";
import AppToggleModal from "./AppToggleModal";
class Dashboard extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         autoSwitch: undefined,
         isModalOpen: false,
         isToggleModalOpen: false,
       }
   }

   componentDidMount() {
      this.setState({ autoSwitch: this.props.enable });
    }
   
   openModal = () => {
      if (this.props.appEmbedStatus) {
        this.setState({
          isModalOpen: true
        });
      } else this.setState({isToggleModalOpen: true});
    };
  
    toggleModal = () => {
      this.setState({
        isModalOpen: !this.state.isModalOpen
      })
    }

  redirectToSettings = () => {
      this.props.history.push('/acs/settings');
  }

  changeAppStatus = () => {
   const postData = {
     enable: !this.state.autoSwitch,
     storeName: this.props.storeName,
   };
   axiosInstance
     .post(apiEndPointUrl + 'setAppStatus', postData)
     .then((res) => {
       this.props.getStateFromChild({ ...postData });
       this.toggleModal();
       this.setState({ autoSwitch: !this.state.autoSwitch }, () => {
         toast.success(<SuccessMsg msg={`${this.state.autoSwitch ? 'Enabled' : 'Disabled'} successfully!`} />)
       });

       // this.props.history.push('/acs/settings/advanced-setting/step-2')
     })
 }

   render() {
      return (
         <div className="content-wrapper-dashboard">
            <div className="app-status-main">
               <span>Auto Currency Switcher app is <span className={this.props.enable ? !this.props.appEmbedStatus ? 'error' : "success" : "error"}>{this.props.enable ? !this.props.appEmbedStatus ? "Disabled" : "Enabled" : "Disabled"}</span></span>
               <button className="btn-custom-secondary" onClick={() => this.openModal()}>Edit</button>
            </div>
            <AppToggleModal
               onClose={() => this.setState({ isToggleModalOpen: false })}
               getStateFromChild={this.props.getStateFromChild}
               storeName={this.props.storeName}
               isOpen={this.state.isToggleModalOpen}
            />
          <div className="line-seperator mb-20"></div>
            <div className="section-wrapper">
               <div className="headerDiv">Go International!</div>


               <div className="mt30">
                  <span className="font-weight-600">Congratulations!</span> Auto currency switcher app is running successfully on your store. Now your international customers
                  can see the prices in their local currencies.
        </div>

               <div className="mt30">
                  Are you looking to customize the look and feel of you currency switcher? You can go to <span className="btn-custom-link" onClick={this.redirectToSettings}>settings</span> tab
        and do it yourself, or send a mail us at <span className="btn-custom-link">support@mlveda.com</span> and our awesome support team will help you out.
        </div>

               <div className="mt30"><span>Happy Selling Globally!</span></div>

            </div>

            <Modal className="confirmPopup" style={{ maxWidth: '385px', marginTop: '25vh' }} size={'lg'} isOpen={this.state.isModalOpen} fade={true} toggle={this.props.toggleModal}>

          <ModalBody className="modal-body-wrapper" >
            <div>
              {this.props.isSetupCompleted && <p>Are you sure you want to {this.state.autoSwitch ? 'disable' : 'enable'} this app on your store?</p>}
              {!this.props.isSetupCompleted && <p>Please complete all the installation steps to enable the app in your store.</p>}
            </div>
            {this.props.isSetupCompleted && <div className="confirmActionBtn" style={{ textAlign: 'right' }}>
              <button className="btn-custom-secondary mr25" onClick={this.toggleModal}>No</button>
              <button className="btn-custom-primary" onClick={this.changeAppStatus}>Yes</button>
            </div>}

            {!this.props.isSetupCompleted && <div className="confirmActionBtn" style={{ textAlign: 'right' }}>
              <button className="btn-custom-primary" onClick={this.toggleModal}>OK</button>
            </div>}

          </ModalBody>

        </Modal>


            {/* <div className="main-partners-app">
                 <div className="partners-app-heading"><span>Work with our partners</span> and grow your business</div>
                 <div>
                  <div className="partners-app-card">
                    <div className="img-section"><img src={pageFly} alt="PageFly" /></div>
                    <div className="description-section">
                      <div className="app-name">
                         PageFly Landing Page builder
                      </div>
                      <div className="app-desc">Build high-converting pages: homepage, product pages, landing pages and more without coding, just drag and drop</div>
                    </div>
                    <div className="button-section">
                      <button className="btn-custom-primary" onClick={() => { window.open("https://apps.shopify.com/pagefly", "_blank") }}>Install</button>
                    </div>
                  </div>
                  <div className="partners-app-card">
                    <div className="img-section"><img src={pageFly} alt="PageFly" /></div>
                    <div className="description-section">
                      <div className="app-name">
                         PageFly Landing Page builder
                      </div>
                      <div className="app-desc">Build high-converting pages: homepage, product pages, landing pages and more without coding, just drag and drop</div>
                    </div>
                    <div className="button-section">
                      <button className="btn-custom-primary" onClick={() => { window.open("https://apps.shopify.com/pagefly", "_blank") }}>Install</button>
                    </div>
                  </div>
                  <div className="partners-app-card">
                    <div className="img-section"><img src={pageFly} alt="PageFly" /></div>
                    <div className="description-section">
                      <div className="app-name">
                         PageFly Landing Page builder
                      </div>
                      <div className="app-desc">Build high-converting pages: homepage, product pages, landing pages and more without coding, just drag and drop</div>
                    </div>
                    <div className="button-section">
                      <button className="btn-custom-primary" onClick={() => { window.open("https://apps.shopify.com/pagefly", "_blank") }}>Install</button>
                    </div>
                  </div>
                 </div>
               </div> */}
            {/* <Modal size={'md'} style={{ maxWidth: '600px', marginTop: '20vh'}} isOpen={this.state.isModalOpen} fade={false} toggle={this.toggleModal}>

               <ModalBody className="modal-body-wrapper" >
               <FontAwesomeIcon className="close-dialog-btn" size={'1x'} icon={faTimes} onClick={this.toggleModal} />
                  <div className="pricing-pop-up">
                     <div className="offer-title">
                        One-time deal for you! Choose Wisely
                  </div>


                     <div>


                        <div className="mt15">
                           We are giving 69% life time discount for your store if you upgrade it now. This is
                           a one-time deal! Choose wisely.
</div>


                     </div>
                     <div className="pricing-btn-container mt25">
                        <span>
                           <div>Yes</div>
                           <div>I want avail lifetime $3/month offer</div>
                        </span>
                        <span>
                           <div>No</div>
                           <div>I will upgrade at $10/month later</div>
                        </span>
                     </div>

                  </div>
               </ModalBody>

            </Modal> */}
         </div>
      )
   }
}

export default Dashboard;