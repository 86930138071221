import React, { Component } from 'react';
import { toast } from 'react-toastify';
import '../styles/last-step.scss';
import axiosInstance from './Common/axiosInstance';
import { apiEndPointUrl } from './Common/constant';
import { SuccessMsg } from './Common/toastConfig';
export class LastStep extends Component {

   constructor(props) {
      super(props)
      this.state = {
         isTextCopied: false,
         sampleReview: this.props.sampleReview
      }
   }


   /*
      copy the text from text area
   */

   copyText() {
      const copyText = document.getElementById('sample-review');
      copyText.select();
      document.execCommand("copy");
      toast.success(<SuccessMsg msg={"Copied"} />);
      this.setState({
         isTextCopied: true
      })
   }

   redirectToReview = () => {
      const params = {
        "storeName":  this.props.storeName,
        "isReviewGiven": true
      };
      axiosInstance
        .post(apiEndPointUrl + 'setSettings', params)
        .then((stepRes) => {
            this.props.getStateFromChild({ ...params });
            window.open("http://apps.shopify.com/auto-currency-switcher#modal-show=ReviewListingModal", "_blank");
            this.props.history.push('/acs/dashboard')
        }).catch((error) => {
          console.log(error);
        });
   }

   changeReview = (event) => {
    this.setState({sampleReview: event.target.value});
  }

   render() {
      return (
         <div className="last-step-wrapper">

<div className="step-title">
               <div className="title-container">
                  <label className="custom-label">Hurray Last Step:</label>
                  <div>We truly appreciate if you could take 10 seconds to give us a
                        genuine review. Your reviews will help us to make our app better.
                  </div>
               </div>
               <div className="section-wrapper">

                  {/* <div className="">
                     <label className="custom-label">Sample review</label>
                     <div onClick={()=>{this.copyText()}} className="copy-btn btn-custom-link">COPY</div>
                  </div> */}
                  {/* <div>
                     <textarea rows="5" id="sample-review" onChange={(e) => this.changeReview(e)} value={this.state.sampleReview}></textarea>
                  </div> */}
                  <div>Your feedback means a lot to us! Please take a minute to leave us a review on the Shopify App Store.</div>
                  <div className="text-align-center mt25">
                     <span className="btn-custom-primary shareLoveWithBtn" onClick={this.redirectToReview}>Share your love with us </span>
                  </div>
                  <div className="btn-custom-link-secondary mt15">
                     <span onClick={() => {
                        this.props.history.push('/acs/dashboard')
                     }}
                     >No thanks, I will do later</span>
                  </div>
               </div>

            </div>
         </div>
      )
   }
}
