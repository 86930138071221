import React from 'react';
import Select from 'react-select';
import { postionMesaureOptions } from '../Common/constant';

const RenderPositions = (props) => {

  
   return (
      <div>

         <div className="row">

            {((props.positionBottomLeftChecked || props.positionBottomRightChecked) && props.showAdvanced)
               ? <div className="col-md-6 col-xs-12">
                  <div className="custom-label">Spacing from Bottom</div>
                  <Select
                     className='custom-select-box topDrop notranslate'
                     classNamePrefix='custom-select-box'
                     onChange={(event) => { props.getPositionMeasure(event, 'positionBottom') }}
                     value={postionMesaureOptions.filter((item) => item.value === props.positionBottom)[0]}
                     options={postionMesaureOptions} />
               </div>
               : null
            }

            {((props.positionTopLeftChecked || props.positionTopRightChecked) && props.showAdvanced)
               ? <div className="col-md-6 col-xs-12">
                  <div className="custom-label">Spacing from Top </div>
                  <Select
                     className='custom-select-box topDrop notranslate'
                     classNamePrefix='custom-select-box'
                     onChange={(event) => { props.getPositionMeasure(event, 'positionTop') }}
                     value={postionMesaureOptions.filter((item) => item.value === props.positionTop)[0]}
                     options={postionMesaureOptions} />
               </div>
               : null
            }

            {((props.positionTopLeftChecked || props.positionBottomLeftChecked) && props.showAdvanced)
               ? <div className="col-md-6 col-xs-12 mt30-responsive">
                  <div className="custom-label">Spacing from Left</div>
                  <Select
                     className='custom-select-box topDrop notranslate'
                     classNamePrefix='custom-select-box'
                     onChange={(event) => { props.getPositionMeasure(event, 'positionLeft') }}
                     value={postionMesaureOptions.filter((item) => item.value === props.positionLeft)[0]}
                     options={postionMesaureOptions} />
               </div>
               : null
            }

            {((props.positionTopRightChecked || props.positionBottomRightChecked) && props.showAdvanced)
               ? <div className="col-md-6 col-xs-12">
                  <div className="custom-label">Spacing from Right</div>
                  <Select
                     className='custom-select-box topDrop notranslate'
                     classNamePrefix='custom-select-box'
                     onChange={(event) => { props.getPositionMeasure(event, 'positionRight') }}
                     value={postionMesaureOptions.filter((item) => item.value === props.positionRight)[0]}
                     options={postionMesaureOptions} />
               </div>
               : null
            }

         </div>

      </div>
   )

}

export default RenderPositions;