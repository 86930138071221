import React, { Component } from 'react'
import { CirclePicker } from 'react-color';
import { Popover } from 'reactstrap';
import { EditableInput } from 'react-color/lib/components/common'
import { SetupBoard } from '../Common/SetupBoard';
import { SetupModalWrapper } from '../Common/SetupModalWrapper';
import { apiEndPointUrl } from '../Common/constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import leftArrow from '../../assets/images/left-arrow.svg';
import { SuccessMsg, ErrorMsg } from '../Common/toastConfig';
import { toast } from 'react-toastify';
import axiosInstance from '../Common/axiosInstance';
export class CustomerMessage extends Component {
      constructor(props) {
            super(props)
            this.state = {
                  messageColor: '#fff000',
                  messageBackgroundColor: '#ff00ff',
                  messageColorPopOver: false,
                  backgroundColorPopOver: false,
                  isShowErrorForColor: false,
                  isSetupModalOpen: false,
                  messageContent: '',
                  defaultMessageContent: '',
                  isMessageDisplay: false,
                  isLoading: false
            }
      }


      componentDidMount() {
            this.setState({
                  messageColor: this.props.messageColor  === 'inherit' ? 'Default' : this.props.messageColor,
                  defaultMessageContent: this.props.defaultMessageContent ? this.props.defaultMessageContent : '',
                  messageBackgroundColor: this.props.messageBackgroundColor  === 'inherit' ? 'Default' : this.props.messageBackgroundColor,
                  messageContent: this.props.messageContent,
                  isMessageDisplay: this.props.isMessageDisplay,
            })
      }
      /* 
           set the pop over for color picker box
      */
      toggleColorPicker = (nameOfPicker) => {
            this.setState({
                  [nameOfPicker]: !this.state[nameOfPicker],
                  isShowErrorForColor: false
            })
      }

      handleColorChange = (value, nameOfTheColor) => {
            var isOk = /^#[0-9A-F]{6}$/i.test(value)
            if (isOk) {
                  this.setState({
                        [nameOfTheColor]: value,
                        isShowErrorForColor: false
                  })
            } else {
                  this.setState({
                        isShowErrorForColor: true
                  })
            }
      }
      handleChangeComplete = (color, nameOfColor) => {
            this.setState({ [nameOfColor]: color.hex });
      };

      /*
         close the modal when click on outside
      */
      toggleSetupVideoModal = () => {
            this.setState({
                  isSetupModalOpen: !this.state.isSetupModalOpen
            })
      }
      /*
            save the current state, update the patent state and goto next step
         */
      saveState = () => {
            this.setState({
                  isLoading: true,
            });
            const postData = {
                  messageBackgroundColor: this.state.messageBackgroundColor === 'Default' ? 'inherit' : this.state.messageBackgroundColor,
                  messageColor: this.state.messageColor === 'Default' ? 'inherit' : this.state.messageColor,
                  storeName: this.props.storeName,
                  messageContent: this.state.messageContent,
                  isMessageDisplay: this.state.isMessageDisplay,
            }

            axiosInstance
                  .post(apiEndPointUrl + 'setSettings', postData)
                  .then((res) => {
                        this.props.getStateFromChild({ ...postData });
                        this.setState({
                              isLoading: false,
                        });
                        toast.success(<SuccessMsg msg={"Saved Successfully."} />);
                        // this.props.history.push('/acs/settings/step-1')
                  }, (error) => {
                        this.setState({
                              isLoading: false,
                        });
                        toast.error(<ErrorMsg msg={"Your session ended. Please login and try again."} />);
                  });

      }

      handleDefaultState = (value, nameOfField) => {
            this.setState({ [nameOfField]: value });
      }

      changeMessageContent = (event) => {
            this.setState({messageContent: event.target.value});
          }

      render() {
            let colorStyle = {
                  messageColorStyle: {
                        background: this.state.messageColor === 'Default' ? 'inherit' : this.state.messageColor
                  },
                  backgroundColorStyle: {
                        background: this.state.messageBackgroundColor === 'Default' ? 'inherit' : this.state.messageBackgroundColor
                  }
            }


            let inputStyles = {
                  input: {
                        width: '100%',
                        marginBottom: '15px'
                  },
            };

            return (
                  <div>
                        <div className="step-title">
                              <div className="title-container">
                                    <div>
                                          <span onClick={() => this.props.history.push('/acs/settings')}
                                                className="btn-custom-back ">  <img src={leftArrow} />
                                          </span>
                                          <label className="custom-label">Cart Notice:</label>
                                    </div>
                                    <div>Currency switcher apps cannot convert the price from checkout page due to Shopify restrictions.
                                    Let us communicate this to your store customers so that they don't get confused.
                  
                  <span className="btn-custom-link help-link" onClick={
                                                () => this.setState({ isSetupModalOpen: true })
                                          } > (Help me setup this step)
                     </span> </div>
                              </div>
                              <div className="section-wrapper">

                                    <div className="row">
                                          <div className="col-xs-12 col-md-12 mt10">
                                                <div className="float-left">
                                                      <label className="custom-label mt10">Message</label>
                                                </div>
                                                <div className="float-right">
                                                      <span></span>
                                                      <div onClick={() => { this.setState({ isMessageDisplay: !this.state.isMessageDisplay }) }}
                                                            className={'switch-wrapper float-right ' + (this.state.isMessageDisplay ? 'on' : 'off')}>
                                                            <div className="is on">
                                                                  Yes
                              <div className="is off">No</div>
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="clear-fix"></div>
                                          </div>
                                          <div className="col-xs-12 col-md-12 ">
                                                <textarea rows="5" value={this.state.messageContent} onChange={(e) => this.changeMessageContent(e)}></textarea>
                                          </div>
                                          <div className="col-xs-12 col-md-12 ">
                                                <span className="set-default" onClick={ () => { this.handleDefaultState(this.state.defaultMessageContent, 'messageContent') }}>Set Default</span>
                                          </div>
                                    </div>

                                    {/* starting of color section */}
                                    <div className="row partition-div-mt-30">
                                          <div className="col-md-6 col-xs-12">
                                                <div className="custom-label mt15">
                                                      Message Color</div>
                                                <div className="color-picker-wrapper">
                                                      <div id="messageColorPopOver" onClick={() => { this.toggleColorPicker('messageColorPopOver') }} className="color-picker">
                                                            <span className="notranslate">{this.state.messageColor}</span>
                                                            <span style={colorStyle.messageColorStyle} className="color-shower"></span>
                                                      </div>
                                                      <Popover placement="bottom" isOpen={this.state.messageColorPopOver} target="messageColorPopOver"
                                                            toggle={() => { this.toggleColorPicker('messageColorPopOver') }}>
                                                            <div className="color-code-wrapper">
                                                                  <EditableInput
                                                                        style={inputStyles}
                                                                        value={this.state.messageColor}
                                                                        onChange={(color) => { this.handleColorChange(color, 'messageColor') }} />
                                                                  <CirclePicker
                                                                        color={this.state.messageColor}
                                                                        onChangeComplete={(color) => this.handleChangeComplete(color, 'messageColor')}
                                                                  />
                                                                  {this.state.isShowErrorForColor ? (
                                                                        <div className="color-picker-error">
                                                                              Please provide a hexadecimal color code with a leading number sign(e.g #00FF00)
                                 </div>
                                                                  ) : null}

                                                            </div>
                                                      </Popover>
                                                </div>

                                          </div>
                                          <div className="col-md-6 lookRightDiv">
                                                <span className="set-default setDefaultCustomMessage" onClick={ () => { this.handleDefaultState('Default', 'messageColor') }}>Set Default</span>
                                          </div>

                                          {/* starting of Background Color */}
                                          <div className="col-md-6 col-xs-12">

                                                <div className="custom-label mt15">
                                                      Background Color</div>
                                                <div className="color-picker-wrapper">
                                                      <div id="backgroundColorPopOver" onClick={() => { this.toggleColorPicker('backgroundColorPopOver') }} className="color-picker">
                                                            <span className="notranslate">{this.state.messageBackgroundColor}</span>
                                                            <span style={colorStyle.backgroundColorStyle} className="color-shower"></span>
                                                      </div>
                                                      <Popover placement="bottom" isOpen={this.state.backgroundColorPopOver} target="backgroundColorPopOver"
                                                            toggle={() => { this.toggleColorPicker('backgroundColorPopOver') }}>
                                                            <div className="color-code-wrapper">
                                                                  <EditableInput
                                                                        style={inputStyles}
                                                                        value={this.state.messageBackgroundColor}
                                                                        onChange={(color) => { this.handleColorChange(color, 'messageBackgroundColor') }} />
                                                                  <CirclePicker
                                                                        color={this.state.messageBackgroundColor}
                                                                        onChangeComplete={(color) => this.handleChangeComplete(color, 'messageBackgroundColor')}
                                                                  />
                                                                  {this.state.isShowErrorForColor ? (
                                                                        <div className="color-picker-error">
                                                                              Please provide a hexadecimal color code with a leading number sign(e.g #00FF00)
                                 </div>
                                                                  ) : null}

                                                            </div>
                                                      </Popover>
                                                </div>
                                          </div>
                                          <div className="col-md-6 lookRightDiv">
                                                <span className="set-default setDefaultCustomMessage" onClick={ () => { this.handleDefaultState('Default', 'messageBackgroundColor') }}>Set Default</span>
                                          </div>
                                          {/* ending of Background Color */}
                                    </div>
                                    {/* ending of color section */}
                                    <div className="save-btn-wrapper">
                                          <button onClick={() => {
                                                this.props.history.push('/acs/settings')
                                          }} className="btn-custom-secondary mr25">Back</button>
                                          <button onClick={() => {
                                                this.saveState()
                                          }} className="btn-custom-primary">
                                                <span>{this.state.isLoading ? <FontAwesomeIcon spin size={'1x'} icon={faSpinner} /> : null}</span>
                                                Save
                     </button>

                                    </div>
                              </div>

                              {/* starting of right side setuo video section   */}
                              <SetupBoard link={"https://www.youtube.com/embed/bzWExycdgEk?autoplay=1"} page={"cartNotice"} />
                              {/* ending of right side setuo video section   */}
                              <div className="clear-fix"></div>
                              {this.state.isSetupModalOpen ?
                                    <SetupModalWrapper toggleModal={this.toggleSetupVideoModal} /> : null}
                        </div>
                  </div>
            )
      }
}
