import React, { Component } from 'react';
import axiosInstance from './Common/axiosInstance';
import '../styles/pricing.scss';
import { Modal, ModalBody } from 'reactstrap';
import thinkingFace from '../assets/images/thinking-face.png';
import { Header } from './Common/Header';
import { apiEndPointUrl } from './Common/constant';
import leftArrow from '../assets/images/left-arrow.svg';
export class PricingPlan extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,

    }
  }
  /*
   open the final modal
*/
  openModal = () => {
    this.setState({
      isModalOpen: true
    })
  }
  /*
     close the modal when click on outside
  */
  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }

  redirectToNextStep = (planType) => {

    if (planType === 'preminum') {
      window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?upgrade=yes&freshInstall=0&shop=" + this.props.storeName;
    } else if (planType === 'freeToPreminum') {
      const apiData = 'https://www.mlveda.com/MultiCurrency/New/setDiscountedPricing?shop=' + this.props.storeName;
      axiosInstance
        .get(apiData)
        .then((res) => {
          window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?upgrade=yes&freshInstall=0&shop=" + this.props.storeName;
        }).catch((error) => {
          console.log(error);
        });
    }
    else {
      window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?shop=" + this.props.storeName + "&downgrade=yes&freshInstall=0";
    }
  }

  render() {
    return (
      <div>
        <Header {...this.props} stepCompletion={100} />

        <div className="pricing-container custom-container">
        
        <div className="pricing-title">
          <span onClick={() => this.props.history.push('/acs/settings')} className="btn-custom-back "><img src={leftArrow} /></span>
          <span style={{ verticalAlign: 'text-bottom' }}>Try our Super Success plan free for 15 days now.</span>
        </div>
        <div className={"pricing-row"}>
          <div className={"col-no-1 top-border position-relative" + (this.props.appPlan === "free" ? " special-border" : "")}>
            {this.props.appPlan === "free" && <div className="recommended-tab">Current Plan</div>}
            <div className="content">
            
              <div className="title">
                Hobbyist
                                           </div>
              <div className="price mt-10">
                <span></span>
                <span>$0</span>
                <span>/mo</span>
                <div className="textSpan">&nbsp;</div>
              </div>

              <div className={"mt30 " + (this.props.appPlan === "free" ? "visibilty-hidden" : "")}>
                <button onClick={() => { this.openModal() }} className={"btn-custom-primary black " + (this.props.appPlan === "free" ? "disabled" : "")} disabled={this.props.appPlan === "free"}>Downgrade</button>
              </div>
            </div>


          </div>

          <div className={"col-no-3 position-relative" + (this.props.appPlan !== "free" ? " special-border" : "")} >
            {this.props.appPlan !== "free" && <div className="recommended-tab">Current Plan</div>}
            <div className="content">
              <div className="title">
                Super Success
                                    </div>
              <div className="price mt-10">
                <span>$29</span>
                <span>$9.95</span>
                <span>/mo</span>
                <div className="textSpan">15 Days Risk Free Trial</div>
              </div>
              <div className={"mt30 " + (this.props.appPlan !== "free" ? "visibilty-hidden" : "")}>
                <button className={"btn-custom-primary install " + (this.props.appPlan !== "free" ? "disabled" : "pulse")}  disabled={this.props.appPlan !== "free"} onClick={() => { this.redirectToNextStep('preminum') }}>Install</button>
              </div>
            </div>



          </div>

          {/* <div className="col-no-2 top-border">
            <div className="content">
              <div className="title">
                Basic
                                           </div>
              <div className="price mt-10">
                <span>$99</span>
                <span>$9</span>
                <span>/mo</span>
              </div>

              <div className="mt30">
                <button onClick={() => { this.openModal() }} className="btn-custom-primary black">Contact us</button>
              </div>
              <div className="asterisk">*we will setup in the next 24 hours</div>
            </div>


          </div> */}
        </div>


        <div className={"pricing-row"}>
          <div className={"col-no-1 position-relative" + (this.props.appPlan === "free" ? " special-border-lrb" : "")} >
            <div className="customer-rate special">
              <b>28%</b> customers are using this
                  </div>
            <div className="infoDiv text">

              <div className="mt-10 tick-sign">175+ currencies Currency Dropdown</div>
              <div className="mt-10 tick-sign"> Free Look and Feel Customisation</div>
              <div className="mt-10 tick-sign">Custom Cart Note</div>
              <div className="mt-10 tick-sign">Chat Support</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
            </div>

          </div>

          <div className={"col-no-3 position-relative" + (this.props.appPlan !== "free" ? " special-border-lrb" : "")} >
            <div className="customer-rate special">
              <b>72%</b> customers are using this
                  </div>
            <div className="infoDiv text">

              <div className="mt-10 tick-sign">175+ currencies Currency Dropdown</div>
              <div className="mt-10 tick-sign"> Free Look and Feel Customisation</div>
              <div className="mt-10 tick-sign">Custom Cart Note</div>
              <div className="mt-10 tick-sign">Email/Phone/Chat Support</div>
              <div className="mt-10 tick-sign">Free UX support to integrate with your theme</div>
              <div className="mt-10 tick-sign">Location based Currency Selection</div>
              <div className="mt-10 tick-sign">Beautiful Country Flags</div>
              <div className="mt-10 tick-sign">Rounded Pricing for better conversions</div>
              <div className="mt-10 tick-sign">Priority, Urgent setup support Reply within 24 hours</div>
              {/* <div className="mt-10 tick-sign">15 Days Risk Free Trial Period</div> */}
            </div>


          </div>

          {/* <div className="col-no-2 top-border">
            <div className="customer-rate">
              <b>28%</b> customers are using this
                  </div>
            <div className="infoDiv text">
              <div className="mt-10 tick-sign">200+  currencies Currency Dropdown</div>
              <div className="mt-10 tick-sign">Free Look and Feel Customisation</div>
              <div className="mt-10 tick-sign">Custom Cart Note</div>
              <div className="mt-10 tick-sign">Email Support</div>
              <div className="mt-10 tick-sign">Free UX support to integrate with your theme</div>
              <div className="mt-10 tick-sign">Location based Currency Selection</div>
              <div className="mt-10 tick-sign">Beautiful Country Flags</div>
              <div className="mt-10 tick-sign">Rounded Pricing for better conversions</div>
              <div className="mt-10 tick-sign">Priority, Urgent setup support Reply within 24 hours</div>
              <div className="mt-10 tick-sign">15 Days Risk Free Trial Period</div>
            </div>
          </div> */}
        </div>

        <Modal size={'md'} style={{ maxWidth: '600px', marginTop: '20vh' }} isOpen={this.state.isModalOpen} fade={false} toggle={this.toggleModal}>

          <ModalBody className="modal-body-wrapper" >
            <div className="pricing-pop-up">
              <div className="heading">Important Message! Please read carefully</div>
              <div className="mt15">Our premium version <span className="bold-text">brings you more success</span> because it shows local prices to your international customers automatically.</div>
              <div className="mt15">But if you want to proceed with downgrading then <span className="underline-text">follow the next steps to setup the free version of the app on your store</span></div>

              <div className="pricing-btn-container mt15 text-left">
                <button className="btn-custom-primary mr25 ml0" onClick={() => { this.redirectToNextStep('free') }}>Confirm</button>
                <button className="btn-custom-secondary" onClick={() => { this.toggleModal() }}>I don't want to downgrade</button>
              </div>
            </div>

            {/* <div className="pricing-pop-up">
              <div className="flex-row">
                <div>
                  <img alt="thinkingPage" className="thinking-face-img" src={thinkingFace} />
                </div>
                <div>
                  <div className="wait-title">Wait!</div>
                  <div className="mt10">
                    Our premium version <b>brings you more success </b> because it shows local prices to your international customers by default.
            </div>
                  
                </div>

              </div>
              <div className="pricing-btn-container">
                <span onClick={() => { this.redirectToNextStep('free') }}>
                  <div>No</div>
                  <div>I don't want more sales</div>
                </span>
                <span onClick={() => { this.toggleModal() }}>
                  <div>Yes</div>
                  <div>I want more sales</div>
                </span>
              </div>

            </div> */}
          </ModalBody>

        </Modal>
      </div>
      </div>

    )
  }
}
