import React from 'react';
import { CirclePicker } from 'react-color';
import { Popover } from 'reactstrap';
import { EditableInput } from 'react-color/lib/components/common'
import { postionMesaureOptions, booleanOptions, fontSizeOptions, borderPaddingOptions } from '../Common/constant';
import Select from 'react-select';
const AdvancedLook = (props) => {
  let colorStyle = {
    textColorStyle: {
      background: props.textColor === 'Default' ? 'inherit' : props.textColor
    },
    textHoverColorStyle: {
      background: props.textHoverColor === 'Default' ? 'inherit' : props.textHoverColor
    },
    borderLineColorStyle: {
      background: props.borderLineColor === 'Default' ? 'inherit' : props.borderLineColor
    },
    backgroundColorStyle: {
      background: props.backgroundColor === 'Default' ? 'inherit' : props.backgroundColor
    },
    hoverBackgroundColorStyle: {
      background: props.hoverBackgroundColor === 'Default' ? 'inherit' : props.hoverBackgroundColor
    }
  }


  let inputStyles = {
    input: {
      width: '100%',
      marginBottom: '15px'
    },
  };

  return (
    <div>
      {/* starting of Border Line Color*/}
      {props.borderLook.value && <div className="col-md-6 col-xs-12 lookLeftDiv inline-block">
        <div className="custom-label partition-div-mt-30">
          Border Line Color</div>
        <div className="color-picker-wrapper">
          <div id="borderLineColorPopOver" onClick={() => { props.toggleColorPicker('borderLineColorPopOver') }} className="color-picker">
            <span className="notranslate">{props.borderLineColor}</span>
            <span style={colorStyle.borderLineColorStyle} className="color-shower"></span>
          </div>
          <Popover placement="bottom" isOpen={props.borderLineColorPopOver} target="borderLineColorPopOver"
            toggle={() => { props.toggleColorPicker('borderLineColorPopOver') }}>
            <div className="color-code-wrapper">
              <EditableInput
                style={inputStyles}
                value={props.borderLineColor}
                onChange={(color) => { props.handleColorChange(color, 'borderLineColor') }} />
              <CirclePicker
                color={props.borderLineColor}
                onChangeComplete={(color) => props.handleChangeComplete(color, 'borderLineColor')}
              />
              {props.isShowErrorForColor ? (
                <div className="color-picker-error">
                  Please provide a hexadecimal color code with a leading number sign(e.g #00FF00)
                                 </div>
              ) : null}

            </div>
          </Popover>
        </div>
      </div>}
      {props.borderLook.value && <div className="col-md-6 col-xs-12 lookRightDiv">
        <span className="set-default" onClick={ () => { props.handleDefaultState('Default', 'borderLineColor') }}>Set Default</span>
      </div>}
      {/* ending of Border Line Color */}

      {/* starting of Text Color*/}
      <div className="col-md-6 col-xs-12 lookLeftDiv inline-block">
        <div className="custom-label partition-div-mt-30">
          Text Color</div>
        <div className="color-picker-wrapper">
          <div id="textColorPopOver" onClick={() => { props.toggleColorPicker('textColorPopOver') }} className="color-picker">
            <span className="notranslate">{props.textColor}</span>
            <span style={colorStyle.textColorStyle} className="color-shower"></span>
          </div>
          <Popover placement="bottom" isOpen={props.textColorPopOver} target="textColorPopOver" toggle={() => { props.toggleColorPicker('textColorPopOver') }}>
            <div className="color-code-wrapper">
              <EditableInput
                style={inputStyles}
                value={props.textColor}
                onChange={(color) => { props.handleColorChange(color, 'textColor') }} />
              <CirclePicker
                color={props.textColor}
                onChangeComplete={(color) => props.handleChangeComplete(color, 'textColor')}
              />
              {props.isShowErrorForColor ? (
                <div className="color-picker-error">
                  Please provide a hexadecimal color code with a leading number sign(e.g #00FF00)
                                 </div>
              ) : null}

            </div>
          </Popover>
        </div>
      </div>
      <div className="col-md-6 col-xs-12 lookRightDiv">
        <span className="set-default" onClick={ () => { props.handleDefaultState('Default', 'textColor') }}>Set Default</span>
      </div>
      {/* ending of Text Color */}

      {/* starting of Text Hover Color*/}
      <div className="col-md-6 col-xs-12 lookLeftDiv inline-block">
        <div className="custom-label partition-div-mt-30">
        Text Hover Color</div>
        <div className="color-picker-wrapper">
          <div id="textHoverColorPopOver" onClick={() => { props.toggleColorPicker('textHoverColorPopOver') }} className="color-picker">
            <span className="notranslate">{props.textHoverColor}</span>
            <span style={colorStyle.textHoverColorStyle} className="color-shower"></span>
          </div>
          <Popover placement="bottom" isOpen={props.textHoverColorPopOver} target="textHoverColorPopOver" toggle={() => { props.toggleColorPicker('textHoverColorPopOver') }}>
            <div className="color-code-wrapper">
              <EditableInput
                style={inputStyles}
                value={props.textHoverColor}
                onChange={(color) => { props.handleColorChange(color, 'textHoverColor') }} />
              <CirclePicker
                color={props.textHoverColor}
                onChangeComplete={(color) => props.handleChangeComplete(color, 'textHoverColor')}
              />
              {props.isShowErrorForColor ? (
                <div className="color-picker-error">
                  Please provide a hexadecimal color code with a leading number sign(e.g #00FF00)
                                 </div>
              ) : null}

            </div>
          </Popover>
        </div>
      </div>
      <div className="col-md-6 col-xs-12 lookRightDiv">
        <span className="set-default" onClick={ () => { props.handleDefaultState('Default', 'textHoverColor') }}>Set Default</span>
      </div>
      {/* ending of Text Hover Color */}

      {/* starting of Background Color */}
      <div className="col-md-6 col-xs-12 lookLeftDiv inline-block">
        <div className="custom-label partition-div-mt-30">
          Background Color</div>
        <div className="color-picker-wrapper">
          <div id="backgroundColorPopOver" onClick={() => { props.toggleColorPicker('backgroundColorPopOver') }} className="color-picker">
            <span className="notranslate">{props.backgroundColor}</span>
            <span style={colorStyle.backgroundColorStyle} className="color-shower"></span>
          </div>
          <Popover placement="bottom" isOpen={props.backgroundColorPopOver} target="backgroundColorPopOver"
            toggle={() => { props.toggleColorPicker('backgroundColorPopOver') }}>
            <div className="color-code-wrapper">
              <EditableInput
                style={inputStyles}
                value={props.backgroundColor}
                onChange={(color) => { props.handleColorChange(color, 'backgroundColor') }} />
              <CirclePicker
                color={props.backgroundColor}
                onChangeComplete={(color) => props.handleChangeComplete(color, 'backgroundColor')}
              />
              {props.isShowErrorForColor ? (
                <div className="color-picker-error">
                  Please provide a hexadecimal color code with a leading number sign(e.g #00FF00)
                                 </div>
              ) : null}

            </div>
          </Popover>
        </div>
      </div>
      <div className="col-md-6 col-xs-12 lookRightDiv">
        <span className="set-default" onClick={ () => { props.handleDefaultState('Default', 'backgroundColor') }}>Set Default</span>
      </div>
      {/* ending of Background Color */}

      {/* starting of Hover Background Color */}
      <div className="col-md-6 col-xs-12 lookLeftDiv inline-block">
        <div className="custom-label partition-div-mt-30">
          Hover Background Color</div>
        <div className="color-picker-wrapper">
          <div id="hoverBackgroundColorPopOver" onClick={() => { props.toggleColorPicker('hoverBackgroundColorPopOver') }} className="color-picker">
            <span className="notranslate">{props.hoverBackgroundColor}</span>
            <span style={colorStyle.hoverBackgroundColorStyle} className="color-shower"></span>
          </div>
          <Popover placement="bottom" isOpen={props.hoverBackgroundColorPopOver} target="hoverBackgroundColorPopOver"
            toggle={() => { props.toggleColorPicker('hoverBackgroundColorPopOver') }}>
            <div className="color-code-wrapper">
              <EditableInput
                style={inputStyles}
                value={props.hoverBackgroundColor}
                onChange={(color) => { props.handleColorChange(color, 'hoverBackgroundColor') }} />
              <CirclePicker
                color={props.hoverBackgroundColor}
                onChangeComplete={(color) => props.handleChangeComplete(color, 'hoverBackgroundColor')}
              />
              {props.isShowErrorForColor ? (
                <div className="color-picker-error">
                  Please provide a hexadecimal color code with a leading number sign(e.g #00FF00)
                                 </div>
              ) : null}
            </div>
          </Popover>
        </div>
      </div>
      <div className="col-md-6 col-xs-12 lookRightDiv">
        <span className="set-default" onClick={ () => { props.handleDefaultState('Default', 'hoverBackgroundColor') }}>Set Default</span>
      </div>
      {/* ending of Hover Background Color */}


      {/* starting of Font Size */}
      {/* <div className="col-md-6 col-xs-12 lookLeftDiv inline-block">
        <div className="custom-label partition-div-mt-30">
        Currency Switcher Size
         </div>
        <Select
          className='custom-select-box'
          classNamePrefix='custom-select-box'
          value={props.fontSize}
          isSearchable={false}
          onChange={(e) => {
            props.handleDropDownChange(e, 'fontSize')
          }}
          options={fontSizeOptions} />
      </div>
      <div className="col-md-6 col-xs-12 lookRightDiv">
        <span className="set-default" onClick={ () => { props.handleDefaultState('inherit', 'fontSize') }}>Set Default</span>
      </div> */}
      {/* ending of Font Size */}

      {/* starting of Border Padding */}
      {/* <div className="col-md-6 col-xs-12 lookLeftDiv">
        <div className="custom-label partition-div-mt-30">
          Border Padding
         </div>

        <Select
          className='custom-select-box'
          classNamePrefix='custom-select-box'
          value={props.borderPadding}
          isSearchable={false}
          onChange={(e) => {
            props.handleDropDownChange(e, 'borderPadding')
          }}
          options={borderPaddingOptions} />
      </div> */}

      {/* ending of Font Size */}
    </div>
  )

}

export default AdvancedLook;