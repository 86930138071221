import React, { Component } from 'react';
import axiosInstance from './Common/axiosInstance';
import '../styles/pricing.scss';
import { Modal, ModalBody } from 'reactstrap';
import hotOffer from '../assets/images/hot-offer.png';
import { apiEndPointUrl } from './Common/constant';
import leftArrow from '../assets/images/left-arrow.svg';
export class Pricing extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,

    }
  }
  /*
   open the final modal
*/
  openModal = () => {
    this.setState({
      isModalOpen: true
    })
  }
  /*
     close the modal when click on outside
  */
  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }

  redirectToNextStep = (planType) => {
    // const params = {
    //   "storeName":  this.props.storeName,
    //   "stepReached": 0
    // };
    // axios
    //   .post(apiEndPointUrl + 'setSettings', params)
    //   .then((stepRes) => {
    //     this.props.setTheCurrentStep(0)
    //     this.props.routeProps.history.push('/welcome');
    //   }).catch((error) => {
    //     console.log(error);
    //   });

    if(planType === 'preminum') {
      window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?upgrade=yes&freshInstall=1&shop=" + this.props.storeName;
    } else if(planType === 'freeToPreminum') {
      const apiData = 'https://www.mlveda.com/MultiCurrency/New/setDiscountedPricing?shop=' + this.props.storeName;
      axiosInstance
      .get(apiData)
      .then((res) => {
        window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?upgrade=yes&freshInstall=1&shop=" + this.props.storeName;
      }).catch((error) => {
        console.log(error);
      });
    }
    else {
        window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?upgrade=no&freshInstall=1&shop=" + this.props.storeName;
    }
  }

  render() {
    return (
      <div className="pricing-container custom-container">
        
        <div className="pricing-title">
          <span style={{verticalAlign: 'text-bottom'}}>Try our Super Success plan free for 15 days now.</span>
            </div>
        <div className="pricing-row">
          <div className="col-no-1 top-border">
            <div className="content">
              <div className="title">
                Hobbyist
                                           </div>
              <div className="price mt-10">
                <span></span>
                <span>$0</span>
                <span>/mo</span>
                <div className="textSpan">&nbsp;</div>
              </div>

              <div className="mt30">
                <button onClick={() => { this.openModal() }} className="btn-custom-primary black">Install</button>
              </div>
            </div>


          </div>

          <div className="col-no-3 special-border position-relative" >
            <div className="recommended-tab">Highly Recommended</div>
            <div className="content">
              <div className="title">
                Super Success
                                    </div>
              <div className="price mt-10">
                <span>$29</span>
                <span>$9.95</span>
                <span>/mo</span>
                <div className="textSpan">15 Days Risk Free Trial</div>
              </div>
              <div className="mt30">
                <button className="btn-custom-primary pulse install" onClick={() => {this.redirectToNextStep('preminum')}}>Install</button>
              </div>
            </div>



          </div>

          {/* <div className="col-no-2 top-border">
            <div className="content">
              <div className="title">
                Basic
                                           </div>
              <div className="price mt-10">
                <span>$99</span>
                <span>$9</span>
                <span>/mo</span>
              </div>

              <div className="mt30">
                <button onClick={() => { this.openModal() }} className="btn-custom-primary black">Contact us</button>
              </div>
              <div className="asterisk">*we will setup in the next 24 hours</div>
            </div>


          </div> */}
        </div>


        <div className="pricing-row">
          <div className="col-no-1 " >
            <div className="customer-rate special">
              <b>28%</b> customers are using this
                  </div>
            <div className="infoDiv text">

              <div className="mt-10 tick-sign">175+ currencies Currency Dropdown</div>
              <div className="mt-10 tick-sign"> Free Look and Feel Customisation</div>
              <div className="mt-10 tick-sign">Custom Cart Note</div>
              <div className="mt-10 tick-sign">Chat Support</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
              <div className="mt-10">&nbsp;</div>
            </div>

          </div>

          <div className="col-no-3 special-border-lrb" >
            <div className="customer-rate special">
              <b>72%</b> customers are using this
                  </div>
            <div className="infoDiv text">

              <div className="mt-10 tick-sign">175+ currencies Currency Dropdown</div>
              <div className="mt-10 tick-sign"> Free Look and Feel Customisation</div>
              <div className="mt-10 tick-sign">Custom Cart Note</div>
              <div className="mt-10 tick-sign">Email/Phone/Chat Support</div>
              <div className="mt-10 tick-sign">Free UX support to integrate with your theme</div>
              <div className="mt-10 tick-sign">Location based Currency Selection</div>
              <div className="mt-10 tick-sign">Beautiful Country Flags</div>
              <div className="mt-10 tick-sign">Rounded Pricing for better conversions</div>
              <div className="mt-10 tick-sign">Priority, Urgent setup support Reply within 24 hours</div>
              {/* <div className="mt-10 tick-sign">15 Days Risk Free Trial Period</div> */}
            </div>


          </div>

          {/* <div className="col-no-2 top-border">
            <div className="customer-rate">
              <b>28%</b> customers are using this
                  </div>
            <div className="infoDiv text">
              <div className="mt-10 tick-sign">200+  currencies Currency Dropdown</div>
              <div className="mt-10 tick-sign">Free Look and Feel Customisation</div>
              <div className="mt-10 tick-sign">Custom Cart Note</div>
              <div className="mt-10 tick-sign">Email Support</div>
              <div className="mt-10 tick-sign">Free UX support to integrate with your theme</div>
              <div className="mt-10 tick-sign">Location based Currency Selection</div>
              <div className="mt-10 tick-sign">Beautiful Country Flags</div>
              <div className="mt-10 tick-sign">Rounded Pricing for better conversions</div>
              <div className="mt-10 tick-sign">Priority, Urgent setup support Reply within 24 hours</div>
              <div className="mt-10 tick-sign">15 Days Risk Free Trial Period</div>
            </div>
          </div> */}
        </div>

        <Modal size={'md'} style={{ maxWidth: '600px', marginTop: '20vh'}} isOpen={this.state.isModalOpen} fade={false} toggle={this.toggleModal}>

          <ModalBody className="modal-body-wrapper" >
            <div className="pricing-pop-up">
              <div className="flex-row">
                <div>
                  <img className="hot-offer-img" src={hotOffer} />
                </div>
                <div>
                  <div className="wait-title">Wait!</div>
                  <div className="mt10">
                    Our premium version <b>brings you more success </b> because it shows local prices to your international customers by default.
            </div>
                  <div className="mt15">
                    We want to give YOU a lifetime 30% discount if you upgrade to premium version now.
            </div>
                </div>

              </div>
              <div className="pricing-btn-container">
                <span onClick={() => {this.redirectToNextStep('free')}}>
                  <div>No</div>
                  <div>I don't want more sales</div>
                </span>
                <span onClick={() => {this.redirectToNextStep('freeToPreminum')}}>
                  <div>Yes</div>
                  <div>I want more sales</div>
                </span>
              </div>

            </div>
          </ModalBody>

        </Modal>
      </div>

    )
  }
}
