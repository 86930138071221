import React from 'react';
import Select, { components } from 'react-select';
import createClass from 'create-react-class';
import { currencySwitcherOptions } from './Common/constant';
import './basic.scss'
const divStyle = {
   margin: '20px',

};

const MultiValue = props => {
   return (
      <components.MultiValue {...props}>
         <span>{props.data.label}</span>
      </components.MultiValue>
   );
};

const Option = createClass({
   render() {
      return (
         <div>
            <components.Option {...this.props}>
               <input
                  className="select-checkbox"
                  type="checkbox"
                  checked={this.props.isSelected}
                  onChange={() => { }}
               />{" "}
               <label className={'select-checkbox-label  ' + (this.props.isSelected ? 'checked' : '')} >
                  <span>{this.props.value}</span> </label>


            </components.Option>
         </div>
      );
   }
});

export default class Basic extends React.Component {
   constructor(props) {
      super(props)
      this.state = {
         showSearch: false,
         searchText: '',
         enteredText: '',
         listOfArray: [{
            value: 'value1',
            isChecked: false
         },
         {
            value: 'value2',
            isChecked: false
         },
         {
            value: 'value3',
            isChecked: false
         },
         {
            value: 'value4',
            isChecked: false
         }],
         filtered: [],
         inputValue: ''
      }
      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
   }
   componentDidMount() {
      this.setState({
         filtered: [...this.state.listOfArray]
      })
      document.addEventListener('mousedown', this.handleClickOutside, false);
      this.isSwitchOn = false;
   }
   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside, false);
   }
   showSearchContainer() {
      this.setState({
         showSearch: true,

      })
   }

   /* ... */

   onInputChange = (query, { action }) => {
      // Prevents resetting our input after option has been selected
      if (action !== "set-value") this.setState({ inputValue: query });
   }

   /**
   * Alert if clicked on outside of element
   */
   handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
         this.setState({
            showSearch: false
         })
      }
   }

   /**
     * Set the wrapper ref
     */
   setWrapperRef(node) {

      this.wrapperRef = node;
   }
   searchInputChanged(event) {
      if (event.target.value !== '') {
         const searchTerm = event.target.value.toLowerCase();
         const newList = [...this.state.listOfArray.filter((item) => {
            return item.value.toLowerCase().includes(searchTerm);

         })]

         this.setState({
            filtered: [...newList]
         })
         console.log(this.state.listOfArray);
      } else {
         this.setState({
            filtered: [...this.state.listOfArray]
         })
      }
   }
   valueChanged(selectedItem, index) {
      let newArray = [...this.state.filtered];
      newArray[index].isChecked = !newArray[index].isChecked;
      this.setState({
         filtered: [...newArray]
      })
      console.log(this.wrapperRef)
   }
   render() {


      const renderedLi = this.state.filtered.map((item, index) =>
         <li key={index} onClick={() => { this.valueChanged(item, index) }} >
            <input
               className="select-checkbox"
               type="checkbox" onChange={() => { }} checked={item.isChecked} />{" "}
            <label className={'select-checkbox-label  ' + (item.isChecked ? 'checked' : '')} >
               <span>{item.value}</span>
            </label>
         </li>
      )
      return (
         <div className="test">

            <div>
               <label className="custom-label"> Font Styles:</label><br />
               <div className="light-font">This is light font.</div>
               <div className="regular-font">This is regular font</div>
               <div className="bold-font">This is bold font</div>
            </div>

            <div className="mt25">
               <label className="custom-label"> Text-box:</label>
               <input type="text"></input>
            </div>
            <div className="mt25">
               <div className="row">
                  <div className="col-md-4">
                     <label className="custom-label">Primary-button:</label><br />
                     <button className="btn-custom-primary">This is button</button></div>
                  <div className="col-md-4">
                     <label className="custom-label">Secondary-button:</label><br />
                     <button className="btn-custom-secondary">This is button</button>
                  </div>
                  <div className="col-md-4">
                     <label className="custom-label">Third-button:</label><br />
                     <button className="btn-custom-third">This is button</button>
                  </div>
               </div>
            </div>
            <div className="mt25">
               <label className="custom-label">Toggle switch:</label><br />
               <div onClick={() => { this.isSwitchOn = !this.isSwitchOn; this.forceUpdate() }}
                  className={'switch-wrapper ' + (this.isSwitchOn ? 'on' : 'off')}>
                  <div className="is on">
                     Show
                     <div className="is off">Hide</div>
                  </div>
               </div>
            </div>

            <div className="mt25">
               <label className="custom-label">Single select dropdown:</label><br />
               <Select
                  className='custom-select-box'
                  classNamePrefix='custom-select-box'
                  isSearchable={false}
                  options={currencySwitcherOptions} />
               <div style={divStyle}></div>
            </div>

            <div className="mt25">
               <label className="custom-label">Multi select dropdown:</label><br />
               <Select
                  inputValue={this.state.inputValue}
                  placeholder={"Add more currencies"}
                  hideSelectedOptions={false}
                  isMulti={true}
                  components={{ Option, MultiValue }}
                  className='custom-select-box'
                  classNamePrefix='custom-select-box'
                  option={Option}
                  options={currencySwitcherOptions}
                  blurInputOnSelect={false} //set by default, but to be sure
                  closeMenuOnSelect={false} //prevents menu close after select, which would also result in input blur
                  onInputChange={this.onInputChange}
               />
            </div>
            <div className="search-wrapper"  >
               <div>
                  <span className="tag-name-container">Test </span>
                  <span className="tag-name-container">Test12 </span>
                  <span className="tag-name-container">Test23 </span>
                  <span className="tag-name-container">Test43 </span>
               </div>
               <div  >
                  <button onClick={() => { this.showSearchContainer() }}>Add search</button>
               </div>
               {
                  this.state.showSearch ?
                     <div ref={this.setWrapperRef}>
                        <div>
                           <input onChange={(e) => { this.searchInputChanged(e) }} type="text" />
                        </div>
                        <div>
                           <ul className="search-result-wrapper">
                              {renderedLi}
                           </ul>
                        </div>
                     </div>
                     : null
               }
            </div>




         </div >
      )
   }

}