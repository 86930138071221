import React, { Component } from 'react';
import '../styles/welcome.scss';
import relationshipManager from '../assets/images/relationship-manager.jpg';
import { CopyBlock, a11yLight } from "react-code-blocks";
export class UninstallationSteps extends Component {

    redirectToThemes = () => {
      const searchParams = new URLSearchParams(window.location.search);
      const shopName = searchParams.get('shop') || '';
      if(shopName) {
        window.open(`https://${shopName}/admin/themes?channel=true`, '_blank');
      }
    }

    render() {
        return (
            <div className="custom-container">
                <div className="welcome-instruction-container uninstall-container">
                    <div className="content-wrapper">
                        <div className="step-title">
                            <div className="title-container">
                                <label className="custom-label">Please follow below steps to remove the app in your theme</label>
                            </div>
                        </div>
                        <div className="mt10">Go to Shopify <span className="btn-custom-link" onClick={this.redirectToThemes}>Admin Panel -> Themes</span>. Click on <span className="bold-font">Actions</span> dropdown and select <span className="bold-font">Edit code</span>.</div>
                        <div class="step1">
                            <div className="sub-title">Step 1: Remove below files in the Snippets folder.</div>
                            <div className="file-list">
                                <div>mlveda-currencies-switcher.liquid</div>
                                <div>mlveda-currencies.liquid</div>
                                <div>mlveda-flag.liquid</div>
                                <div>mlveda-currencies-style.liquid</div>
                            </div>
                        </div>
                        {/* <div class="step2">
                            <div className="sub-title">Step 2: Put the Cart notice by using the below steps</div>
                            <div className="sub-step1">
                                <div className="mt5">1. Create a mlveda-cartnote.liquid in the section and put the below code in this file:</div>
                                <div>
                                <CopyBlock
                                    language={"jsx"}
                                    text={`{% if settings.mlvedaACS_isMessageDisplay %}
    <p class="mlvedaCartNote" style="display:none">
    {{ settings.mlvedaACS_messageContent | replace: "<<shop.name>>", shop.name | replace:"<<shop.currency>>",shop.currency|replace:"<<shop.selected_currency>>","<span class='selected-currency'></span>"}}
    </p>
{% endif %}
{% schema %}
    {
        "name": "Section name",
        "settings": []
    }
{% endschema %}
{% stylesheet %}
{% endstylesheet %}
{% javascript %}
{% endjavascript %}`}
                                    showLineNumbers={false}
                                    theme={a11yLight}
                                    wrapLines={true}
                                    codeBlock
                                    />
                                </div>
                            </div>
                            <div className="sub-step1">
                                <div className="mt5">2. Put the below code in the cart.json file in template::</div>
                                <div>
                                <CopyBlock
                                    language={"jsx"}
                                    text={`{
    "sections": {
        "mlvedacartnote": {
            "type": "mlveda-cartnote",
            "settings": {}
        }
    },
    "order": [
        "mlvedacartnote"
    ]
}`}
                                    showLineNumbers={false}
                                    theme={a11yLight}
                                    wrapLines={true}
                                    codeBlock
                                    />
                                </div>
                            </div>
                        </div> */}
                        <div class="step3">
                            <div className="sub-title">Step2: Remove the below code from the theme.liquid file.</div>
                            <div className="sub-step1">
                                <div className="mt5">{"1. Remove the below code above </head> tag:"}</div>
                                <div>
                                <CopyBlock
                                    language={"javascript"}
                                    text={`<script>
    window.mlvedaShopCurrency = "{{ shop.currency }}";
    window.shopCurrency = "{{ shop.currency }}";
    window.supported_currencies = "{{ settings.mlvedaACS_supportedCurrencies }}";
</script>`}
                                    showLineNumbers={false}
                                    theme={a11yLight}
                                    wrapLines={true}
                                    codeBlock
                                    />
                                </div>
                            </div>
                            <div className="sub-step1">
                                <div className="mt5">{"2. Remove the below code above </body> tag:"}</div>
                                <div>
                                <CopyBlock
                                    language={"javascript"}
                                    text={`{% include 'mlveda-currencies-switcher' %}
{% include 'mlveda-currencies' %}
{% include 'mlveda-flag' %}
{% include 'mlveda-currencies-style' %}`}
                                    showLineNumbers={false}
                                    theme={a11yLight}
                                    wrapLines={true}
                                    codeBlock
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="welcome-help-footer">
                            <img src={relationshipManager} alt="Relationship Manager" />
                            <div>If you have any queries sent us an email to <span className="btn-custom-link">support@mlveda.com</span>.</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
