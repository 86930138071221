import React from 'react';
import '../../styles/common/header.scss';
import { appNameOptions, apiEndPointUrl } from './constant';
import { Progress } from 'reactstrap';
import axiosInstance from './axiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCog, faQuestionCircle, faTimesCircle, faHome, faWrench } from '@fortawesome/free-solid-svg-icons';
import {
  NavLink,

} from 'react-router-dom';
import { Modal, ModalBody } from 'reactstrap';
import logo from '../../assets/images/logo.png';
import leftArrow from '../../assets/images/left-arrow.svg';
import reviewImg from '../../assets/images/reviewImg.png';
import smilingFaceWithHeartEyes from '../../assets/images/smilingFaceWithHeartEyes.png';
import cryingFace from '../../assets/images/cryingFace.png';
import foldedHands from '../../assets/images/folded-hands.png';
import Translate from './Translate';
/*contains html and code for header*/
export class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenuBar: false,
      showLabelName: 'Currency',
      isShowLabel: false,
      selectedProduct: undefined,
      showNotification: false,
      currentTab: 0,
      isModalOpen: false
    }
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setNotificationRef = this.setNotificationRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  onRouteChanged() {

    if (this.props.location.pathname === '/acs/settings/step-1' || this.props.location.pathname === '/acs/settings/currencies') {

      this.setState({
        showLabel: 'Currencies',
        isShowLabel: true,
        currentTab: 1
      })
    }
    else if (this.props.location.pathname === '/acs/settings/step-2' || this.props.location.pathname === '/acs/settings/location') {
      this.setState({
        showLabelName: 'Location',
        isShowLabel: true,
        currentTab: 1
      })
    }
    else if (this.props.location.pathname === '/acs/settings/step-3' || this.props.location.pathname === '/acs/settings/store-integration') {
      this.setState({
        showLabelName: 'Store Integration',
        isShowLabel: true,
        currentTab: 1
      })
    }
    else if (this.props.location.pathname === '/acs/settings/advanced-setting/step-1' || this.props.location.pathname === '/acs/settings/advanced-setting/look') {
      this.setState({
        showLabelName: 'Look',
        isShowLabel: true,
        currentTab: 1
      })
    }
    else if (this.props.location.pathname === '/acs/settings/advanced-setting/step-2' || this.props.location.pathname === '/acs/settings/advanced-setting/pricing-rules') {
      this.setState({
        showLabelName: 'Pricing Rules',
        isShowLabel: true,
        currentTab: 1
      })
    }
    else if (this.props.location.pathname === '/acs/settings/advanced-setting/step-3' || this.props.location.pathname === '/acs/settings/advanced-setting/cart-notice') {
      this.setState({
        showLabelName: 'Cart Notice',
        isShowLabel: true,
        currentTab: 1
      })
    }
    else if (this.props.location.pathname === '/acs/settings/partner-apps') {
      this.setState({
        showLabelName: 'Partner Apps',
        isShowLabel: true,
        currentTab: 1
      })
    }
    else if (this.props.location.pathname === '/acs/dashboard') {
      this.setState({
        showLabelName: 'Dashboard',
        isShowLabel: false,
        currentTab: 0
      })
    }
    else if (this.props.location.pathname === '/acs/setup') {
      this.setState({
        showLabelName: 'Setup',
        isShowLabel: false,
      })
    }
    else if (this.props.location.pathname === '/acs/help') {
      this.setState({
        showLabelName: 'Support',
        isShowLabel: false,
        currentTab: 2
      })
    } else if (this.props.location.pathname === '/acs/faq') {
      this.setState({
        showLabelName: 'FAQ',
        isShowLabel: false,
        currentTab: 3
      })
    }
    else {
      this.setState({
        showLabelName: 'Settings',
        isShowLabel: false,
        currentTab: 1
      })
    }
  }

  openModal = () => {
    this.setState({
      isModalOpen: true
    })
  }

  toggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen
    })
  }

  handleRoute = (tab) => {
    this.setState({ currentTab: tab }, () => {
      switch (tab) {
        case 0:
          this.props.history.push('/acs/dashboard');
          break;
        case 1:
          this.props.history.push('/acs/settings');
          break;
        case 2:
          this.props.history.push('/acs/help');
          break;
        case 3:
          this.props.history.push('/acs/faq');
          break;
        default:
          this.props.history.push('/acs/dashboard');
      }

    })
  }

  componentDidMount() {
    this.setState({
      enable: this.props.enable,
      selectedProduct: appNameOptions.filter(i => i.value == "bottom_with_left")[0]
    })
    this.onRouteChanged();
    // document.addEventListener('mousedown', this.handleClickOutside, true);
  }
  componentWillUnmount() {
    //  document.removeEventListener('mousedown', this.handleClickOutside, true);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  /**
* Alert if clicked on outside of element
*/
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        showMenuBar: false
      })
    }

    // console.log('Evetnt', event)
    if (this.notificationRef && !this.notificationRef.contains(event.target) && event.target.nodeName !== 'path') {
      this.setState({
        showNotification: false
      })
    }
  }
  /**
    * Truncate the string after specified characters
 */
  truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
  }
  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {

    this.wrapperRef = node;
  }

  setNotificationRef(node) {
    this.notificationRef = node;
  }

  upgradePlan = () => {
    window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?upgrade=yes&shop=" + this.props.storeName
  }

  redirectToReview = () => {
    const params = {
      "storeName":  this.props.storeName,
      "isReviewGiven": true
    };
    axiosInstance
      .post(apiEndPointUrl + 'setSettings', params)
      .then((stepRes) => {
          this.props.getStateFromChild({ ...params });
          window.open("http://apps.shopify.com/auto-currency-switcher#modal-show=ReviewListingModal", "_blank");
          this.setState({isModalOpen: false});
      }).catch((error) => {
        console.log(error);
      });
 }

  redirectToPricing = () => {
    if(this.props.isSetupCompleted) {
      this.props.history.push('/pricing-plan');
    } else {
      this.props.history.push('/pricing');
    }
  }

  render() {
    const width = window.screen.width;
    return (
      <div className="">
        {this.props.isSetupCompleted && this.props.appPlan === 'free' && <div className="main-top-bar">
          {/* {this.props.appPlan !== 'free' && <span className="paid-text">Great! You are using paid plan.</span>} */}
          {this.props.appPlan === 'free' && <span>Increase your international sales upto 25%.<button className="btn-custom-primary" onClick={() => this.redirectToPricing()}>Get 66% lifetime discount now</button></span>}
        </div>}
        <div className="header">
          <div className="header-main-div">
            <div className="app-name-wrapper">
              <div>
                <img src={logo} alt="Logo" />
                <label className="appNameLabel" onClick={() => { this.props.history.push('/acs/dashboard') }}>{this.state.selectedProduct ? this.state.selectedProduct.label : null}</label>
              </div>
            </div>
            {this.props.isSetupCompleted && <div className="menu-bar">
              <ul>
                <li className={this.state.currentTab === 0 ? 'selected' : ''} onClick={() => this.handleRoute(0)}>
                  <a>
                    <span>
                      <div className="icon"><FontAwesomeIcon icon={faHome} /></div>
                      <div className="label">Dashboard</div>
                    </span>
                  </a>
                </li>
                <li className={this.state.currentTab === 1 ? 'selected' : ''} onClick={() => this.handleRoute(1)}>
                  <a>
                    <span>
                      <div className="icon"><FontAwesomeIcon icon={faCog} /></div>
                      <div className="label">Settings</div>
                    </span>
                  </a>
                </li>
                <li className={this.state.currentTab === 2 ? 'selected' : ''} onClick={() => this.handleRoute(2)}>
                  <a>
                    <span>
                      <div className="icon"><FontAwesomeIcon icon={faWrench} /></div>
                      <div className="label">Support</div>
                    </span>
                  </a>
                </li>
                <li className={this.state.currentTab === 3 ? 'selected' : ''} onClick={() => this.handleRoute(3)}>
                  <a>
                    <span>
                      <div className="icon"><FontAwesomeIcon icon={faQuestionCircle} /></div>
                      <div className="label">FAQ</div>
                    </span>
                  </a>
                </li>
              </ul>
            </div>}
            {!this.props.isSetupCompleted && <div className="progress-wrapper">
              <Progress animated value={this.props.stepCompletion} />
            </div>}
            <div className="review-wrapper" onClick={() => this.openModal()}><img src={reviewImg} alt="Review" /></div>
            {(!this.props.isSetupCompleted || width > 768) && <div className="translate-wrapper">
              <Translate />
            </div>}

            {(this.props.isSetupCompleted) ?
              <div className="app-burger-section" >
                {!this.state.isShowLabel ?
                  <span onClick={() => { this.setState({ showMenuBar: true }) }}>
                    <FontAwesomeIcon size={'2x'} icon={faBars} />
                  </span> : null
                }
                <span>
                  {this.state.isShowLabel ? <img onClick={() => { this.props.history.push('/acs/settings') }}
                    className="back-arrow" src={leftArrow} /> : null
                  }
                  <label className={"custom-label " + (!this.state.isShowLabel ? 'center' : "")}>
                    {this.state.showLabelName}
                  </label>
                </span>

              </div> : null
            }
          </div>
        </div>
        {(this.props.isSetupCompleted) ?
          <div ref={this.setWrapperRef} className={"sidebar-menu-wrapper " + (this.state.showMenuBar ? 'show' : "")}>
            <div className="app-name-dropdown">
              <div className="mb-app-name">
                <img src={logo} alt="Logo" />
                <label className="appNameLabel" onClick={() => { this.props.history.push('/acs/dashboard'); this.setState({ showMenuBar: false }) }}>{this.state.selectedProduct ? this.state.selectedProduct.label : null}</label>
              </div>
            </div>
            <div className="side-menu-responsive">
              <ul className="page-navigation">
                <li>
                  <NavLink onClick={() => { this.setState({ showMenuBar: false }) }}
                    activeClassName="selected" className="navbar-link"
                    to={`/acs/dashboard`}>
                    Dashboard
                           </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => { this.setState({ showMenuBar: false }) }} activeClassName="selected" className="navbar-link "
                    to={`/acs/settings/`}>
                    Settings
                           </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => { this.setState({ showMenuBar: false }) }} activeClassName="selected" className="navbar-link"
                    to={`/acs/help`}>
                    Support
                           </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => { this.setState({ showMenuBar: false }) }} activeClassName="selected" className="navbar-link"
                    to={`/acs/faq`}>
                    FAQ
                           </NavLink>
                </li>

              </ul>
            </div>
            {width <= 768 && <div>
              <Translate />
            </div>}
            {this.state.showMenuBar && <div className="close-icon" onClick={() => { this.setState({ showMenuBar: false }) }}><FontAwesomeIcon icon={faTimesCircle} /></div>}
          </div> : null}

        <Modal className="reviewPopup" style={{ maxWidth: '500px', marginTop: '20vh' }} size={'lg'} isOpen={this.state.isModalOpen} toggle={() => this.toggleModal()} backdrop={true}>

          <ModalBody className="modal-body-wrapper" >
            
              <div className="heading">
                <div>Please share your experience with us</div>
                <img src={foldedHands} alt="Folded hands" />
              </div>
              
              <div className="body-main">
                {/* <div className="first-section">
                  <div>You are awesome</div>
                  <div>Please give us a 5-star review</div>
                </div> */}
                <div className="second-section">
                We have put in a lot of effort to create a simple currency switching app. It is built exclusively for Shopify store owners to increase their international sales.
                </div>
                <div className="second-section">
                We are continuously looking to make our app better. So, please write a <span>genuine feedback of your experience.</span>
                </div>
                <div className="third-section">
                  <button className="btn-custom-primary" onClick={() => this.redirectToReview()}><img src={smilingFaceWithHeartEyes} alt="Smiling" /><h2>YES!</h2><span>I would like to write a review now</span></button>
                  <button className="btn-custom-primary mr25" onClick={() => this.toggleModal()}><img src={cryingFace} alt="Crying" /><h2>NO!</h2><span> I will review it after few days</span></button>
                </div>
                <div className="fourth-section">
                  <div className="message">Contact us on <span>support@mlveda.com</span> if you have any queries.</div>
                </div>
              </div>

          </ModalBody>

        </Modal>
      </div>
    )
  }

}