import React, { Component } from 'react'
import '../../styles/common/settings.scss';
import {
   BrowserRouter as Router,
   Route,
   NavLink,
   Switch,
   Redirect
} from 'react-router-dom';
import { Currencies } from '../KeyCustomization/Currencies';
import Location from '../KeyCustomization/Location';
import StoreIntegration from '../KeyCustomization/StoreIntegration';
import { LastStep } from '../LastStep';
import { Look } from '../AdvancedCustomization/Look';
import { PricingRules } from '../AdvancedCustomization/PricingRules';
import { CustomerMessage } from '../AdvancedCustomization/CustomerMessage';
import { PartnerApps } from '../Common/PartnerApps';
import { SettingMenu } from './SettingMenu';
export class Settings extends Component {

   constructor(props) {
      super(props)
   }

   


   render() {
      return (
         <div>
            {!this.props.isSetupCompleted && <Switch>
               <Route exact path={`${this.props.routeProps.match.path}`} component={(props) => <SettingMenu  {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/step-1`} component={(props) => <Currencies {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/step-2`} component={(props) => <Location {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/step-3`} component={(props) => <StoreIntegration  {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/last-step`} component={(props) => <LastStep   {...this.props}  />} />
               <Route path='*' exact={true} component={(props) => { return <Redirect to={this.props.stepReached == -1 ? '/pricing': (this.props.stepReached == 0 ? '/welcome' : `/acs/settings/step-${this.props.stepReached}`)} /> }} />
            </Switch>}

            {this.props.isSetupCompleted && <Switch>
               <Route exact path={`${this.props.routeProps.match.path}`} component={(props) => <SettingMenu  {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/step-3`} component={(props) => <StoreIntegration  {...this.props} /> } />
               <Route path={`${this.props.routeProps.match.path}/currencies`} component={(props) => <Currencies {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/location`} component={(props) => <Location {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/store-integration`} component={(props) => <StoreIntegration  {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/advanced-setting/look`} component={(props) => <Look {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/advanced-setting/pricing-rules`} component={(props) => <PricingRules {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/advanced-setting/cart-notice`} component={(props) => <CustomerMessage {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/partner-apps`} component={(props) => <PartnerApps {...this.props} />} />
               <Route path={`${this.props.routeProps.match.path}/last-step`} component={(props) => <LastStep   {...this.props}  />} />
               <Route path='*' exact={true} component={(props) => { return <Redirect to={'/acs/dashboard'} /> }} />
            </Switch>}
         </div>

      )
   }
}
