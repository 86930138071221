import React, { Component } from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
export class SetupModalWrapper extends Component {
   constructor(props) {
      super(props);
      this.state = {
         isModalOpen: true
      }
   }

   render() {
      return (
         <div>

            <Modal isOpen={this.state.isModalOpen} style={{ marginTop: '10vh' }} fade={false} toggle={this.props.toggleModal}>

               <ModalBody className="video-modal-body-wrapper" >
               <div onClick={this.props.toggleModal} className="close-btn-video"><FontAwesomeIcon size={'2x'} icon={faTimes} /></div>
                  <div className="setup-video-wrapper">
                     <iframe src={this.props.link}

                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                  </div>
               </ModalBody>

            </Modal>
         </div>
      )
   }
}
