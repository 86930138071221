import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

class ProConfirmPopup extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  upgradePlan = () => {
    this.props.upgradePlan();
  }

  closePopup = () => {
    this.props.toggleModal(this.props.currentPopupOpenedFor);
  }

  render() {
    return (
      <Modal className="confirmPopup" style={{ maxWidth: '385px', marginTop: '25vh' }} size={'lg'} isOpen={true} fade={true} toggle={this.props.toggleModal}>

        <ModalBody className="modal-body-wrapper" >
          <div>
            <p>This feature is available in only paid version.</p>
          </div>
          <div className="confirmActionBtn" style={{ textAlign: 'right'}}>
            <button className="btn-custom-secondary mr25" onClick={() => {this.closePopup()}}>Cancel</button>
            <button className="btn-custom-primary" onClick={() => {this.upgradePlan()}}>Upgrade</button>
          </div>

        </ModalBody>

      </Modal>
    )
  }
}

export default ProConfirmPopup;