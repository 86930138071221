import React, { Component } from 'react';
import '../../styles/common/setup-board.scss';
import { SetupModalWrapper } from './SetupModalWrapper';
import playButton from '../../assets/images/youtube.svg';
import lookImg from '../../assets/images/look.jpg';
import pricingRulesImg from '../../assets/images/pricing_rules.jpg';
import cartNoticeImg from '../../assets/images/cartNotice.jpg';
import step1Img from '../../assets/images/step1.jpg';
import step2Img from '../../assets/images/step2.jpg';
import step3Img from '../../assets/images/step3.jpg';


export class SetupBoard extends Component {
      constructor(props) {
            super(props);
            this.state = {
                  isModalOpen: false
            }
      }
      /*
         open video player in full screen on click of image
      */
      openVideoPlayerInFullScreen = () => {
            this.setState({
                  isModalOpen: true
            })
      }
      /*
         close the modal when click on outside
      */
      toggleModal = () => {
            this.setState({
                  isModalOpen: !this.state.isModalOpen
            })
      }
      render() {
            const image = this.props.page === 'look' ? lookImg :
                  (this.props.page === 'pricingRule' ? pricingRulesImg :
                        (this.props.page === 'cartNotice' ? cartNoticeImg :
                              (this.props.page === 'step1' ? step1Img :
                                    (this.props.page === 'step2' ? step2Img :
                                          (this.props.page === 'step3' ? step3Img : null)))));
            return (

                  <div onClick={() => this.openVideoPlayerInFullScreen()} className="setup-container">
                        <div>
                              <button className="btn-custom-third">Setup Help</button>
                        </div>
                        <div className="video-player-mockup videoBackground" style={{ backgroundImage: `url(${image})` }}>
                              <div>
                                    {/* <FontAwesomeIcon icon={faVideo} /> */}
                                    {/* <div className="videoImg" ></div> */}
                                    <img src={playButton} />
                              </div>
                        </div>
                        {/* <div className="setup-video-wrapper">
               <iframe src="https://www.youtube.com/embed/wDsB38Y6WVo?autoplay=1"

                  frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen></iframe>
            </div> */}
                        <div className="mlveda-ads-wrapper">
                              100% free setup by MLVeda
            </div>

                        {this.state.isModalOpen ?
                              <SetupModalWrapper toggleModal={this.toggleModal} link={this.props.link} /> : null}



                  </div>

            )
      }
}
