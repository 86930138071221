import React, { Component } from 'react'
import { pricingRoundingOptions, apiEndPointUrl, roundUptoOptions } from '../Common/constant';
import Select from 'react-select';
import { SetupBoard } from '../Common/SetupBoard';
import { SetupModalWrapper } from '../Common/SetupModalWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import leftArrow from '../../assets/images/left-arrow.svg';
import ProConfirmPopup from '../Common/ProConfirmPopup';
import { SuccessMsg, ErrorMsg } from '../Common/toastConfig';
import { toast } from 'react-toastify';
import axiosInstance from '../Common/axiosInstance';

export class PricingRules extends Component {
      constructor(props) {
            super(props)
            this.state = {
                  pricingRoundingRules: null,
                  isSetupModalOpen: false,
                  hideDecimal: false,
                  autoSwitch: false,
                  roundDecimalValue: null,
                  isLoading: false,
                  isModalOpen: false,
                  currentPopupOpenedFor: null
            }
      }

      componentDidMount() {
            this.setState({
                  hideDecimal: this.props.hideDecimal,
                  autoSwitch: this.props.autoSwitch,
                  pricingRoundingRules: this.props.roundDecimal,
                  roundDecimalValue: roundUptoOptions.filter(i => i.value === this.props.roundDecimalValue)[0]
            })
      }
      /*
       close the modal when click on outside
    */
      toggleSetupVideoModal = () => {
            this.setState({
                  isSetupModalOpen: !this.state.isSetupModalOpen
            })
      }

      /*
         set the state bases on selected dropdown
      */
      handleDropDownChange = (selectedDropDownOption, nameOfDropDown) => {
            if(nameOfDropDown == 'roundDecimalValue' && selectedDropDownOption && this.props.appPlan ==  "free") {
                  this.openModal('roundDecimalValueNode');
                  return;
            }
            this.setState({
                  [nameOfDropDown]: selectedDropDownOption

            });
      }

      /*
         save the current state, update the patent state and goto next step
      */
      saveState = () => {
            this.setState({
                  isLoading: true,
            });
            const postData = {
                  hideDecimal: this.state.hideDecimal,
                  autoSwitch: this.state.autoSwitch,
                  storeName: this.props.storeName,
                  roundDecimal: this.state.pricingRoundingRules,
                  roundDecimalValue: this.state.roundDecimalValue.value
            };

            axiosInstance
                  .post(apiEndPointUrl + 'setSettings', postData)
                  .then((res) => {
                        // postData.priceRounding = this.state.pricingRoundingRules? this.state.pricingRoundingRules.value: null;
                        this.props.getStateFromChild({ ...postData });
                        toast.success(<SuccessMsg msg={"Saved Successfully."} />);
                        // this.props.history.push('/acs/settings/advanced-setting/step-3')
                  }, (error) => {
                        this.setState({
                              isLoading: false,
                        });
                        toast.error(<ErrorMsg msg={"Your session ended. Please login and try again."} />);
                  });

      }

      handleToogle = (nameOfToogle) => {
            if (nameOfToogle == 'autoSwitch' && !this.state.autoSwitch && this.props.appPlan == "free") {
                  this.openModal();
                  return;
            }
            if (nameOfToogle == 'hideDecimal' && !this.state.hideDecimal && this.props.appPlan == "free") {
                  this.openModal();
                  return;
            }
            if (nameOfToogle == 'pricingRoundingRules' && !this.state.pricingRoundingRules && this.props.appPlan == "free") {
                  this.openModal();
                  return;
            }

            // Remove Decimal and Pricing Rounding Rules both should not be true simultaneously.
            if (nameOfToogle == 'hideDecimal' && !this.state.hideDecimal && this.state.pricingRoundingRules) {
                  this.setState({pricingRoundingRules: false});
            }
            if (nameOfToogle == 'pricingRoundingRules' && !this.state.pricingRoundingRules && this.state.hideDecimal) {
                  this.setState({hideDecimal: false});
            }
            this.setState({
                  [nameOfToogle]: !this.state[nameOfToogle]
            });
      }

      openModal = (nodeName) => {
            this.setState({
                  isModalOpen: true,
                  currentPopupOpenedFor: nodeName
            });
      }

      toggleModal = (nodeName) => {
            this.setState({
                  isModalOpen: !this.state.isModalOpen
            });
            if(nodeName === 'roundDecimalValueNode') {
                  this[nodeName].focus();
            }
      }

      upgradePlan = () => {
            window.location.href = "https://www.mlveda.com/MultiCurrency/New/install?upgrade=yes&shop=" + this.props.storeName
      }

      render() {
            return (
                  <div>
                        <div className="step-title">
                              <div className="title-container">
                                    <div>
                                          <span onClick={() => this.props.history.push('/acs/settings')}
                                                className="btn-custom-back ">  <img src={leftArrow} />
                                          </span>
                                          <label className="custom-label">Pricing Rules:</label>
                                    </div>
                                    <div>Let's customize the product prices after conversion like rounding the proper digits or hiding
                                    the decimal places so that your customers will always see the cleaner prices
                  <span className="btn-custom-link help-link" onClick={
                                                () => this.setState({ isSetupModalOpen: true })
                                          } > (Help me setup this step)
                     </span> </div>
                              </div>
                              <div className="section-wrapper">

                                    {/* starting of setting for ip location */}
                                    <div className="row partition-div-mt-30">
                                          <div className="col-md-10">
                                                <div>
                                                      <label className="custom-label custom-label-ip">Auto switch currency based on IP Location</label>
                                                      <span className="proLink proLink-ip">Pro</span>
                                                </div>
                                                <div>
                                                      Customers from different countries will see the prices in their local currency by default
                        </div>
                                          </div>
                                          <div className="col-md-2">
                                                <div onClick={() => { this.handleToogle('autoSwitch') }}
                                                      className={'switch-wrapper float-right ' + (this.state.autoSwitch ? 'on' : 'off')}>
                                                      <div className="is on">
                                                            Yes
                              <div className="is off">No</div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* ending of setting for ip location */}

                                    {/* starting of setting for ip location */}
                                    <div className="row partition-div-mt-30">
                                          <div className="col-md-10">
                                                <div>
                                                      <label className="custom-label">Remove Decimals<span className="proLink">Pro</span></label>
                                                </div>
                                                <div>
                                                Customers will see prices without decimal values.
                        </div>
                                          </div>
                                          <div className="col-md-2">
                                                <div onClick={() => {
                                                      this.handleToogle('hideDecimal')
                                                }}
                                                      className={'switch-wrapper float-right ' + (this.state.hideDecimal ? 'on' : 'off')}>
                                                      <div className="is on">
                                                            On
                              <div className="is off">off</div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* ending of setting for ip location */}

                                    {/* starting of setting for pricing rounding rule */}
                                    <div className="row partition-div-mt-30">
                                          <div className="col-md-10">
                                                <div>
                                                      <label className="custom-label">Pricing Rounding Rules<span className="proLink">Pro</span></label>
                                                </div>
                                                Customers will see prices with rounded decimal values.
                                          </div>
                                          <div className="col-md-2">
                                                <div onClick={() => {
                                                      this.handleToogle('pricingRoundingRules')
                                                }}
                                                      className={'switch-wrapper float-right ' + (this.state.pricingRoundingRules ? 'on' : 'off')}>
                                                      <div className="is on">
                                                            On
                              <div className="is off">off</div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {/* ending of setting for pricing rounding rule */}

                                    {/* starting of setting for round upto */}
                                    {this.state.pricingRoundingRules && <div className="row partition-div-mt-30">
                                          <div className="col-md-12">
                                                <div>
                                                      <label className="custom-label">Round upto<span className="proLink">Pro</span></label>
                                                </div>
                                                <div>
                                                      <Select
                                                            className='custom-select-box topDrop'
                                                            classNamePrefix='custom-select-box'
                                                            value={this.state.roundDecimalValue}
                                                            isSearchable={false}
                                                            ref={(ref) => this.roundDecimalValueNode = ref}
                                                            onChange={(e) => {
                                                                  this.handleDropDownChange(e, 'roundDecimalValue')
                                                            }}
                                                            options={roundUptoOptions} />

                                                            <div>
                           <ul className="round-upto-notice">
                              <li>
                              Customise the decimal values of the product price that you want your customers to see. Ex: If you select 0.99 and your converted product price is $12.32 then your customers will actually see $12.99.
                              </li>
                           </ul>
                        </div>
                                                </div>
                                          </div>

                                    </div>}
                                    {/* ending of setting for round upto */}

                                    {/* starting of setting for Pricing Rounding Rules */}
                                    {/* <div className="row partition-div-mt-30">
                                          <div className="col-md-8">
                                                <div>
                                                      <label className="custom-label">Pricing Rounding Rules<span className="proLink">Pro</span></label>
                                                </div>
                                                <div>
                                                      <Select
                                                            className='custom-select-box'
                                                            classNamePrefix='custom-select-box'
                                                            value={this.state.pricingRoundingRules}
                                                            isSearchable={false}
                                                            onChange={(e) => {
                                                                  this.handleDropDownChange(e, 'pricingRoundingRules')
                                                            }}
                                                            options={pricingRoundingOptions} />
                                                </div>
                                          </div>

                                    </div> */}
                                    {/* ending of setting for Pricing Rounding Rules */}
                                    <div className="save-btn-wrapper">
                                          <button onClick={() => {
                                                this.props.history.push('/acs/settings')
                                          }} className="btn-custom-secondary mr25">Back</button>
                                          <button onClick={() => {
                                                this.saveState()
                                          }} className="btn-custom-primary">
                                                <span>{this.state.isLoading ? <FontAwesomeIcon spin size={'1x'} icon={faSpinner} /> : null}</span>
                                                Save
                     </button>
                                    </div>
                              </div>


                              {/* starting of right side setuo video section   */}
                              <SetupBoard link={"https://www.youtube.com/embed/Q3e5K6JzCoM?autoplay=1"} page={"pricingRule"} />
                              {/* ending of right side setuo video section   */}
                              <div className="clear-fix"></div>
                              {this.state.isModalOpen ?
                                    <ProConfirmPopup upgradePlan={this.upgradePlan} currentPopupOpenedFor={this.state.currentPopupOpenedFor} toggleModal={this.toggleModal} /> : null}
                              {this.state.isSetupModalOpen ?
                                    <SetupModalWrapper toggleModal={this.toggleSetupVideoModal} /> : null}
                        </div>
                  </div>
            )
      }
}
