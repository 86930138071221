import React, { Component } from 'react';
import { toast } from 'react-toastify';
import '../../styles/store-integration.scss';
import { SetupBoard } from '../Common/SetupBoard';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faSpinner, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { SetupModalWrapper } from '../Common/SetupModalWrapper';
import leftArrow from '../../assets/images/left-arrow.svg';
import success from '../../assets/images/success.svg';
import { SuccessMsg, ErrorMsg } from '../Common/toastConfig';
import axiosInstance from '../Common/axiosInstance';

import { apiUrl, apiEndPointUrl } from '../Common/constant';
export default class StoreIntegration extends Component {
      constructor(props) {
            super(props)
            this.state = {
                  isModalOpen: false,
                  isSetupModalOpen: false,
                  isErrorModalOpen: false,
                  isValidSpan: false,
                  isLoading: false
            }
      }
      /*
         open the final modal
      */
      openModal = () => {
            this.setState({
                  isModalOpen: true
            })
      }

      componentDidMount() {
            if (this.props.isSetupCompleted && ((window.location.href).indexOf('step-3') > 0)) {
                  this.setState({ isModalOpen: true });
            }
      }
      /*
         close the modal when click on outside
      */
      toggleModal = (stateName) => {
            this.setState({
                  [stateName]: !this.state[stateName]
            })
      }

      /*
       close the modal when click on outside
    */
      toggleSetupVideoModal = () => {
            this.setState({
                  isSetupModalOpen: !this.state.isSetupModalOpen
            })
      }

      /*
         copy the selected text in hidden textbox
      */
      validateSpan = () => {
            this.setState({
                  isLoading: true,
            })
            const url = apiEndPointUrl + 'validateSpan?shop=' + this.props.storeName;
            axiosInstance
                  .get(url)
                  .then((res) => {
                        if (res.data.isValid) {
                              if (!this.props.isSetupCompleted) {
                                    this.validateIntergration();

                                    // this.setState({ isModalOpen: true, isLoading: false });
                              } else {
                                    this.setState({
                                          isLoading: false,
                                    });
                                    toast.success(<SuccessMsg msg={"Validated Successfully."} />);
                              }
                        } else {
                              // alert('Error in setup the ACS');
                              this.setState({ isErrorModalOpen: true, isLoading: false })
                        }
                  }, (error) => {
                        this.setState({
                              isLoading: false,
                        });
                        toast.error(<ErrorMsg msg={"Your session ended. Please login and try again."} />);
                  });


      }
      copyText(selectedId) {
            const copyText = document.getElementById(selectedId);
            copyText.select();
            document.execCommand("copy");
            toast.success(<SuccessMsg msg={"Copied"} />);
      }

      redirectToStep = () => {
            this.props.history.push('/acs/dashboard');
            // if (this.props.isReviewGiven) {
            //       this.props.history.push('/acs/dashboard');
            // } else {
            //       this.props.history.push('/acs/settings/last-step');
            // }
      }

      validateIntergration = () => {
            const postDataForInt = {
                  stepReached: 4,
                  storeName: this.props.storeName,
                  isSetupCompleted: true
            };

            axiosInstance
                  .post(apiEndPointUrl + 'setSettings', postDataForInt)
                  .then((res) => {

                        // this.props.getStateFromChild({ ...postDataForInt })
                        this.props.setTheCurrentStep(4);

                        const postData = {
                              enable: true,
                              storeName: this.props.storeName,
                        }

                        axiosInstance
                              .post(apiEndPointUrl + 'setAppStatus', postData)
                              .then((res) => {
                                    this.props.getStateFromChild({ ...postData, ...postDataForInt });
                                    this.setState({ isModalOpen: true, isLoading: false });
                                    // if (this.props.isReviewGiven) {
                                    //       this.props.history.push('/acs/dashboard');
                                    // } else {
                                    //       this.props.history.push('/acs/settings/last-step');
                                    // }
                              })
                  }, (error) => {
                  })
      }

      goBack = () => {
            if (!this.props.isSetupCompleted) {
                  this.props.setTheCurrentStep(2);
                  this.props.history.push('/acs/settings/step-2');
            } else {
                  this.props.history.push('/acs/settings');
            }
      }

      redirectToGeneral = () => {
            window.open(`https://${this.props.storeName}/admin/settings/general`, '_blank');
      }

      redirectToStore = () => {
            window.open(`https://${this.props.storeName}`, "_blank");
      }

      render() {
            const codeStringWithMoney = this.props.money_with_currency_format
            const codeStringWithOutMoney = this.props.money_format
            return (
                  <div>
                        <div className="step-title">
                              <div className="title-container">
                                    {this.props.isSetupCompleted ? (
                                          <div>
                                                <span onClick={() => this.props.history.push('/acs/settings')}
                                                      className="btn-custom-back ">  <img src={leftArrow} />
                                                </span>
                                                <label className="custom-label">Store integration:</label>
                                          </div>
                                    ) :
                                          <label className="custom-label">Step 3:</label>
                                    }
                                    <div>Let's now put the currency switcher in your store. Just follow the five steps given one by one.
                     <span className="btn-custom-link help-link" onClick={
                                                () => this.setState({ isSetupModalOpen: true })
                                          } > (Help me setup this step)
                     </span>
                                    </div>
                              </div>
                              <div className="section-wrapper">
                                    <div className="store-integration-instructions">
                                          <div>1. Navigate to Shopify <span className="btn-custom-link" onClick={this.redirectToGeneral}>Admin Panel -> Settings -> Store Defaults</span></div>
                                          <div>2. In Currency Display, click on three dots in the right corner</div>
                                          <div>3. Click on Change Currency Formatting</div>
                                          <div>4. Update HTML with currency field with the below value</div>
                                    </div>
                                    <div className="mt15">
                                          <label className="custom-label">HTML with currency
                     {/* <span className="info-icon"><FontAwesomeIcon icon={faQuestionCircle} /></span> */}

                                          </label>
                                          <div onClick={() => { this.copyText('codeStringWithMoneyCopy') }} className="copy-btn btn-custom-link">COPY</div>
                                          <div className="code-string">
                                                <input className="copyInput" type="text" id="codeStringWithMoneyCopy" value={codeStringWithMoney} readOnly />
                                                {/* <span>   {codeStringWithMoney}</span> */}
                                          </div>
                                    </div>
                                    <div className="partition-div-mt-30"></div>
                                    <div className="">5. Update HTML without currency field with the below value</div>
                                    <div className="mt15">
                                          <label className="custom-label">HTML without currency</label>
                                          <div onClick={() => { this.copyText('codeStringWithOutMoneyCopy') }} className="copy-btn btn-custom-link">COPY</div>
                                          <div className="code-string">
                                                <input className="copyInput" type="text" id="codeStringWithOutMoneyCopy" value={codeStringWithOutMoney} readOnly />
                                                {/* <span>{codeStringWithOutMoney}</span> */}

                                          </div>
                                    </div>

                                    <div className="partition-div-mt-30"></div>
                                    <div className="">6. Click on Save button in the Shopify page</div>
                                    {this.props.os2 && <div className="">7. Go to <span className="btn-custom-link" onClick={() => window.open(`https://${this.props.storeName}/admin/themes/current/editor?context=apps&activateAppId=92ab730f-097e-4571-b27c-3f449e098ca3/mlveda-currencies-head`, "_blank")}>App Embeds Block</span> and click on Save button in top right corner.</div>}

                                    <div className="dotted-partition"> </div>
                                    <div className="contact-us">
                                          If you are facing any problem then please send an email to <span className="btn-custom-link font-14 notranslate">
                                                support@mlveda.com
                  </span> and we   will revert you back within 24 hours.
                  </div>
                                    <div className="save-btn-wrapper">
                                          <button onClick={() => {
                                                this.goBack()
                                          }} className="btn-custom-secondary mr25">Back</button>
                                          <button onClick={() => this.validateSpan()}
                                                className={"btn-custom-primary " + (this.state.isLoading ? "disabled" : '')}>
                                                <span>{this.state.isLoading ? <FontAwesomeIcon spin size={'1x'} icon={faSpinner} /> : null}</span>

                                                Validate the integration</button>
                                    </div>
                              </div>
                              {/* starting of right side setuo video section   */}
                              <SetupBoard link={"https://www.youtube.com/embed/Bj2iQaQVtNI?autoplay=1"} page={"step3"} />
                              {/* ending of right side setuo video section   */}
                              <div className="clear-fix"></div>

                        </div>
                        {/* <input type="text" onChange={() => { }} value={codeStringWithMoney} id="codeStringWithMoney" />
            <input type="text" onChange={() => { }} value={codeStringWithOutMoney} id="codeStringWithOutMoney" /> */}

                        <Modal className="success-popup" isOpen={this.state.isModalOpen} fade={false} >

                              <ModalBody className="modal-body-wrapper" >

                                  <div className="main-sec">
                                    <div className="success-main">
                                    <svg version="1.1" className="success-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                      <circle className="path circle" fill="none" stroke="#04cea4" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                                      <polyline className="path check" fill="none" stroke="#04cea4" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                                    </svg>
                                      {/* <img src={success} alt="success" className="successImg" /> */}
                                      {/* <div className="congrats-text">Congratulations Andrew</div> */}
                                      <div className="success-msg">Auto Currency Switcher is setup successfully!</div>
                                    </div>
                                    <div className="empower-msg">
                                      <span className="font-weight-600">Awesome.</span> Currency switcher is live on your store. Verfiy if the currency switcher is working properly on <span className="btn-custom-link" onClick={() => {this.redirectToStore()}}>your store</span>. If it's working properly
                                    </div>
                                    <div className="last-step-btn success-button-sec">
                                      <button onClick={() => {
                                            this.redirectToStep()
                                      }} className="btn-custom-primary ">
                                            Go to Dashboard
                                            {/* {this.props.isReviewGiven ? 'Go to Dashboard' : 'Go to Last Step'} */}
                                      </button>
                                    </div>
                                  </div>
                                  <div className="success-notice">If you find any issue, please send an email to <span className="btn-custom-link notranslate">support@mlveda.com</span> and we will get back to you within 24 hours.</div>


                                    {/* <div className="modal-title">
                                          <img src={success} alt="success" className="successImg" />Auto Currency Switcher has been added to your store<span className="btn-custom-link verifyLink" onClick={() => {this.redirectToStore()}}>Verify it</span>
                  </div>
                                    <div className="modal-content-wrapper">
                                          
                     <div>
                                                <span className="inline-block">If it's working perfect on your store, then go to  </span>

                                                <div className="last-step-btn">
                                                      <button onClick={() => {
                                                            this.redirectToStep()
                                                      }} className="btn-custom-primary ">
                                                            {this.props.isReviewGiven ? 'Goto Dashboard' : 'Last Step'}
                                                      </button>
                                                </div>

                                                <div className="mt10"></div>

                                          </div>
                                          <div className="dotted-partition"> </div>
                                          <div className="mt30 notice-support">
                                                <span>If you find any issue, please send a mail to </span>
                                                <span className="btn-custom-link notranslate">support@mlveda.com</span> and we will get back to you within 24 hours.
                     </div>
                                    </div> */}


                              </ModalBody>

                        </Modal>


                        <Modal size={'lg'} isOpen={this.state.isErrorModalOpen} fade={false} toggle={() => { this.toggleModal('isErrorModalOpen') }}>

                              <ModalBody className="modal-body-wrapper" >
                                    
                                    <div className="modal-content-wrapper">

                                    <div className="noticeDiv-store">
                                          <div className="importantIcon-store">!</div>
                                    </div>

                                          This step could not be validated successfully. Please check the instructions again. Incase you are facing any issues please send an email to <span className="btn-custom-link notranslate">support@mlveda.com</span> and we will revert you back within 24 hours.
                                    </div>
                                    <div className="errorPopupAction">
                                          <button onClick={() => { this.toggleModal('isErrorModalOpen') }} className="btn-custom-primary ">
                                                OK
                                          </button>
                                    </div>

                              </ModalBody>

                        </Modal>


                        {this.state.isSetupModalOpen ?
                              <SetupModalWrapper toggleModal={this.toggleSetupVideoModal} /> : null}
                  </div>
            )
      }
}
