import React from 'react';
import { components } from 'react-select';
import createClass from 'create-react-class';

export const MultiValue = props => {
   return (
      <components.MultiValue {...props}>
         <span>{props.data.label}</span>
      </components.MultiValue>
   );
};

export const Option = createClass({
   render() {

      return (

         <div>

            <components.Option {...this.props}>
               <input
                  className="select-checkbox"
                  type="checkbox"
                  checked={this.props.isSelected}
                  onChange={() => { }}
               />{" "}
               <label className={'select-checkbox-label  ' + (this.props.isSelected ? 'checked' : '')} >
                  <span>{this.props.label}</span> </label>


            </components.Option>
         </div>
      );
   }
});