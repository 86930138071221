import React, { Component } from 'react';
import $ from 'jquery';

import '../../styles/common/translate.scss';

export default class Translate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ele: undefined,
    };
  }

  /** Creates googleTranslate script tag and appends it to document.head */
  createScriptTag = () => {
    const scriptTag = $(
      '<script id="googleTranslateScriptTag" type="text/javascript" src="https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit" />',
    );
    scriptTag.appendTo(document.head);
  };

  replaceTranslateElement = () => {
    if (window.googleTranslateElement) {
      if ($('.Polaris-TopBar__Contents').length > 0) {
        $('.Polaris-TopBar__Contents').append(window.googleTranslateElement);
        $(window.googleTranslateElement).removeClass('googleTranslateFixedEle');
      } else {
        $('#google_translate_element')
          .parent()
          .append(window.googleTranslateElement);
        $(window.googleTranslateElement).addClass('googleTranslateFixedEle');
      }
    }
  };

  componentDidMount() {
    if (!window.googleTranslateElement) {
      window.googleTranslateElement = document.getElementById(
        'google_translate_element',
      );
    }
    this.replaceTranslateElement();

    if ($('#googleTranslateScriptTag').length === 0) {
      this.createScriptTag();
    }
  }

  componentDidUpdate() {
    this.replaceTranslateElement();
  }

  render() {
    return <div id='google_translate_element' />;
  }
}
