import React, { Component } from 'react';
import { MultiValue, Option } from '../Common/common';
import Select from 'react-select';
import '../../styles/currency.scss';
import { SetupBoard } from '../Common/SetupBoard';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, fas, faSpinner, faSpin } from '@fortawesome/free-solid-svg-icons';
import { SetupModalWrapper } from '../Common/SetupModalWrapper';
import { apiEndPointUrl } from '../Common/constant';
import leftArrow from '../../assets/images/left-arrow.svg';
import exclamation from '../../assets/images/exclamation-sign-in-a-circle.svg';
import { SuccessMsg, ErrorMsg } from '../Common/toastConfig';
import { toast } from 'react-toastify';
import axiosInstance from '../Common/axiosInstance';


export class Currencies extends Component {
   constructor(props) { 
      super(props)
      this.state = {
         inputValue: '',
         supportedCurrencies: [],
         currency: [],
         optionsForDefaultCurrency:[],
         defaultCurrencyArray: {},
         isMultiSelectOpen: false,
         isModalOpen: false,
         isSetupModalOpen: false,
         isLoading: false
      }
   }

   /*
     open the final modal
  */
   openModal = () => {
      this.setState({


         isModalOpen: true
      })
   }
   /*
      close the modal when click on outside
   */
   toggleModal = () => {
      this.setState({
         isModalOpen: !this.state.isModalOpen
      })
   }
   /*
       close the modal when click on outside
    */
   toggleSetupVideoModal = () => {
      this.setState({
         isSetupModalOpen: !this.state.isSetupModalOpen
      })
   }
   componentDidMount() {
      const currency = [];
      let supportedCurrencies = [];
      const optionsForDefaultCurrency=[];
      let defaultCurrencyArray = {};
      for (let item in this.props.currencies) {
         if (item === this.props.homeCurrency) {
            currency.push({ value: item, label: item + ' - ' + this.props.currencies[item], disabled: true });
            // optionsForDefaultCurrency.push({ value: item, label: item + ' - ' + this.props.currencies[item]});
         } else {
            currency.push({ value: item, label: item + ' - ' + this.props.currencies[item], disabled: false });
            
         }
         if (item === this.props.defaultCurrency) {
            defaultCurrencyArray = { value: item, label: item + ' - ' + this.props.currencies[item] };
         }
      }

      const tmpSupportedCurrencies = this.props.supportedCurrencies.trim().split(' ');
      for (let innerItem of tmpSupportedCurrencies) {
         const foundCurrency = currency.filter((item) => item.value === innerItem)[0];
         supportedCurrencies.push(foundCurrency);
         if(foundCurrency) {
            optionsForDefaultCurrency.push({ value: foundCurrency.value, label: foundCurrency.label });
         }
      }
      const sortCurrency = this.sortCurrency(currency);
      this.setState({
         supportedCurrencies: supportedCurrencies,
         currency: sortCurrency,
         defaultCurrencyArray: defaultCurrencyArray,
         optionsForDefaultCurrency:optionsForDefaultCurrency
      })

   }

   sortCurrency = (arr) => {
      arr.sort(function(a, b){
            if(a.label < b.label) return -1;
            if(a.label > b.label) return 1;
            return 0;
        });
      return arr;
   }

   /*
      get the value from default currency
    */
   handleDropDownChange = (selectedDropDownOption, nameOfDropDown) => {
      this.setState({
         defaultCurrencyArray: selectedDropDownOption
      });
   }

   /*
      get the value for supported currencies
      if it is the last then set INR as default
   */
   setValueForSupportedCurrencies = (selectedDropDownOption, nameOfDropDown) => {
      let supportedCurrenciesString = '';
      if (selectedDropDownOption.length > 0) {
         this.setState({
            supportedCurrencies: selectedDropDownOption,
            optionsForDefaultCurrency:selectedDropDownOption
         });
         const itemFound=selectedDropDownOption.filter((item)=>item.value===this.state.defaultCurrencyArray.value)
         this.setState({ inputValue: '' });
         if(itemFound.length<=0)
       {
               this.setState({defaultCurrencyArray:selectedDropDownOption[0]})
         
            
         }

      } else {
         if (nameOfDropDown === 'supportedCurrencies') {
            const foundCurrency = this.state.currency.filter((item) => item.value === this.props.homeCurrency);
            this.setState({
               supportedCurrencies: foundCurrency
            });
         }
      }
   }
   /* ... */

   onInputChange = (query, { action }) => {
      // Prevents resetting our input after option has been selected
      if (action !== "set-value") this.setState({ inputValue: query });
   }

   /* 
      set all currency as selected
   */
   selectAllCurrency = () => {
      const tmpSelectedCurrency = Object.assign([], this.state.supportedCurrencies);
      for (let innerItem of this.state.currency) {
            const item = this.state.supportedCurrencies.find(i => i.value === innerItem.value);
            if(!item) {
                  tmpSelectedCurrency.push(innerItem);
            }
      }
      this.setState({
         supportedCurrencies: tmpSelectedCurrency,
         optionsForDefaultCurrency:tmpSelectedCurrency
      });
   }
   /*
      open multi select dropdowns on click of edit link
   */
   openDropDown = () => {
      // this.setState({
      //    isMultiSelectOpen: true
      // })
      this.multiSelect.focus();
   }

   /* 
    remove all currency from selected and set default currency as INR
  */
   removeAllCurrency = () => {
      const optionsForDefaultCurrency=[];
      const tmpData = this.state.currency.filter((item) => item.value === this.props.homeCurrency);
      this.setState({
         supportedCurrencies: tmpData
      },()=>{
         optionsForDefaultCurrency.push(this.state.supportedCurrencies[0]);
         this.setState({
            optionsForDefaultCurrency:optionsForDefaultCurrency,
            defaultCurrencyArray: optionsForDefaultCurrency[0]
         });
      })

     
   }

   /*
     save the current state, update the patent state and goto next step
  */
   saveState = () => {
      this.setState({
         isLoading: true
      })
      let supportedCurrencies = '';
      for (let item of this.state.supportedCurrencies) {
         supportedCurrencies += item.value + ' '
      }

      let postData = {
            supportedCurrencies: supportedCurrencies,
            defaultCurrency: this.state.defaultCurrencyArray.value,
            storeName: this.props.storeName
      }
      if(!this.props.isSetupCompleted) {
            postData.stepReached = 2;
      }
      axiosInstance
         .post(apiEndPointUrl + 'setSettings', postData)
         .then((res) => {
            this.props.getStateFromChild({ ...postData, currentStep: 2 });
            if(!this.props.isSetupCompleted) {
                  this.props.history.push('/acs/settings/step-2')
            } else {
                  toast.success(<SuccessMsg msg={"Saved Successfully."} />);
            }
         }, (error) => {
            this.setState({
               isLoading: false
            })
            toast.error(<ErrorMsg msg={"Your session ended. Please login and try again."} />);
         })

   }

   goBack = () => {
      if(!this.props.isSetupCompleted) {
            this.props.setTheCurrentStep(0);
            this.props.history.push('/welcome');
      } else {
            this.props.history.push('/acs/settings');
      }
   }

   setHomeCurrency = () => {
         this.setState({defaultCurrencyArray: this.state.optionsForDefaultCurrency.filter(i => i.value === this.props.homeCurrency)[0]})
   }

   render() {
      const styles = {

         multiValueRemove: (base, state) => {
            return state.data.value === this.props.homeCurrency ? { ...base, display: 'none' } : base;
         }
      };
      return (
         <div>
            <div className="step-title">
               <div className="title-container">
                  {this.props.isSetupCompleted ? (
                     <div>
                        <span onClick={() => this.props.history.push('/acs/settings')} className="btn-custom-back ">
                           <img src={leftArrow} />  </span>
                        <label className="custom-label">Currencies:</label>
                     </div>
                  ) :
                     <label className="custom-label">Step 1:</label>}
                  <div>Let's choose the currencies that you want to choose in your Shopify store out of 175+ currencies
                  <span className="btn-custom-link help-link" onClick={
                        () => this.setState({ isSetupModalOpen: true })
                     } > (Help me setup this step)
                     </span>
                  </div>
               </div>
               <div className="section-wrapper">

                  {/* starting of supported currencies */}
                  <div className="">

                     <div className="custom-label float-left" >
                        My Currencies &nbsp;
                     <span onClick={() => this.openDropDown()} className="btn-custom-link">Edit</span>
                     </div>
                     <div className="float-right">
                        <span onClick={() => this.selectAllCurrency()} className="btn-custom-link">Select all </span>&nbsp;/&nbsp;
                     <span onClick={() => this.removeAllCurrency()} className="btn-custom-link"  >Remove all</span>
                     </div>
                     <div className="clear-fix"></div>
                     <Select
                        isOptionDisabled={(option) => option.disabled === true}
                        isMulti
                        menuIsOpen={this.state.isMultiSelectOpen}
                        ref={(node) => { this.multiSelect = node }}
                        inputValue={this.state.inputValue}
                        placeholder={"Add more currencies"}
                        hideSelectedOptions={false}
                        isMulti={true}
                        styles={styles}
                        components={{ Option, MultiValue }}
                        className='custom-select-box currecy-custom notranslate'
                        classNamePrefix='custom-select-box'
                        option={Option}
                        blurInputOnSelect={false} //set by default, but to be sure
                        closeMenuOnSelect={false} //prevents menu close after select, which would also result in input blur
                        onInputChange={this.onInputChange}
                        value={this.state.supportedCurrencies}
                        onFocus={() => {
                           this.setState({
                              isMultiSelectOpen: true
                           })
                        }}
                        onBlur={() => {
                           this.setState({
                              isMultiSelectOpen: false
                           })
                        }}
                        onChange={(e) => this.setValueForSupportedCurrencies(e, 'supportedCurrencies')}
                        options={this.state.currency} />
                        <div className="btn-add-currency">
                              <button onClick={() => this.openDropDown() } className="btn-custom-secondary addCurrency">Add a currency</button>
                        </div>
                        </div>
                     {/* ending of supported currencies */}

                     <div className="partition-div-mt-30"></div>

                     {/* starting of Default Currency */}
                     <div className="">

                        <div className="custom-label float-left defaultCurrencyText">
                        My Default Currency<span className="restWorldText">(For Rest of The World)</span>
                     </div>
                        <div className="float-right btn-custom-link" onClick={this.setHomeCurrency}>
                           Use store currency
                     </div>
                        <div className="clear-fix"></div>
                        <Select
                           className='custom-select-box notranslate'

                           classNamePrefix='custom-select-box'
                           value={this.state.defaultCurrencyArray}
                           isSearchable={true}
                           onChange={(e) => {
                              this.handleDropDownChange(e, 'defaultCurrency')
                           }}
                           options={this.state.optionsForDefaultCurrency} />
                        <div>
                           <ul className="default-currency-notice">
                              <li>
                                 Default currency is the currency in which your prices will be
                                 displayed when a customer opens to your store.
                              </li>
                           </ul>
                        </div>

                     </div>
                     {/* ending of Currency Currency */}
                     <div className="save-btn-wrapper">
                        <button className="btn-custom-secondary mr25" onClick={() => {this.goBack()}}>Back</button>
                        <button onClick={() => {
                           this.saveState()
                        }} className={"btn-custom-primary " + (this.state.isLoading ? "disabled" : '')}>
                           <span>{this.state.isLoading ? <FontAwesomeIcon spin size={'1x'} icon={faSpinner} /> : null}</span>
                           {this.props.isSetupCompleted ? 'Save' : 'Save and goto step 2'}</button>

                     </div>
                  </div>

                  {/* starting of right side setuo video section   */}
                  <SetupBoard link={"https://www.youtube.com/embed/N4ER2Ug6vek?autoplay=1"} page={"step1"} />
                  {/* ending of right side setuo video section   */}
                  <div className="clear-fix"></div>

               </div>


               <Modal isOpen={this.state.isModalOpen} fade={false} toggle={this.toggleModal}>

                  <ModalBody className="note-modal-body-wrapper" >
                     <div className="text-align-center">
                        <span>
                           <img className="notice-image" src={exclamation} />
                        </span><br />
                        <span className="bold-text">Important Note</span>

                     </div>
                     <div className="mt30">
                        Currency switcher apps cannot convert the price from checkout page duw to Shopify restrictions.
               </div>
                     <div className="mt20">
                        Are you looking to empower your store to checkout also in converted currency! We can help.
                     We have done this for more than 800+ stores please <span className="btn-custom-link">contact us </span>
                        and we will revert you back within 24 hours.
   
                  </div>
                     <div className="mt30 text-align-center">
                        <button onClick={() => {
                           this.setState({
                              isModalOpen: !this.state.isModalOpen
                           })
                        }} className="btn-custom-primary" >Yes, I want Multi Currency Checkout</button>
                        <button className="btn-custom-alert mt15">I don't Multi Currency Checkout</button>
                     </div>
                     <div className="dotted-partition"> </div>
                     <div className="preview-notice">
                        Preview on your store and use
                     <span className="btn-custom-link">Advanced Settings </span>
                        If you want to change location, look, currency rounding etc.
                  </div>
                  </ModalBody>

               </Modal>
               {this.state.isSetupModalOpen ?
                  <SetupModalWrapper toggleModal={this.toggleSetupVideoModal} /> : null}
            </div>
            )
         }
      }
